import { useState } from 'react';
import { useStore } from '../../store/Store';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { registerFormComponents, alphaSort99 } from '../../lib/formHelpers';
import { profileCompleteUI } from '../../schemas';
import OnboardingWizardItem from '../OnboardingWizardItem/OnboardingWizardItem';
import { patchUser, addReferrer } from '../../lib/api';

function CreateProfileComplete(props) {
  const { state, dispatch } = useStore();
  const history = useHistory();

  const sourceOptions = [...state.staticValues.sources.sort(alphaSort99)];
  
  const [referrer, setReferrer ] = useState(state.user?.referrer || '');
  const [referrerUpdated, setReferrerUpdated ] = useState(false);

  const formParams = {
    resolver: joiResolver(profileCompleteUI),
    defaultValues: {
      sourceId: state.user?.source?.id?.toString() || '0',
    }
  }

  const { register, handleSubmit, formState: { errors } } = useForm(formParams);
  const controlledInput = registerFormComponents(register, errors);

  async function onSubmit(data) {
    try {
      if (referrerUpdated) { 
        const referrerData = {
          referreeId: state.user.contactId,
          referrer: referrer
        }
        await addReferrer(referrerData);
       }
    }
    catch { 
      // It's ok if this doesn't work 
    }

    try {
      const updatedData = {
        sourceId: data.sourceId,
        wizard1Page: 99,
      }

      await patchUser(props.isMentor, state.user, updatedData, dispatch);
      
      history.push('/');
    }
    catch (err) {
      console.log('Mentor Complete err', err);
    }
  }

  return (
    <OnboardingWizardItem title="Thank you!" description="You're all set!  We'll get you to your dashboard in a moment." handleSubmit={handleSubmit(onSubmit)} {...props.nav}>
      <div className="wizard-max-inner">
        <Form.Field>
          <Form.Label>How did you hear about us?</Form.Label>
          { controlledInput('sourceId', 'select', { className: "is-fullwidth", inner: sourceOptions.map((theSource) => 
              <option key={`hear_${theSource.id}`} value={theSource.id}>{theSource.description}</option>) })
          }
        </Form.Field>
        <Form.Field>
          <Form.Label>If you were referred to us by another member, please enter their phone number here:</Form.Label>
          <Form.Input value={referrer} onChange={e => { setReferrer(e.target.value); setReferrerUpdated(true);}}></Form.Input>
        </Form.Field>
      </div>

    </OnboardingWizardItem>

  )
}

export default CreateProfileComplete;