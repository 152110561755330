import SelectionBox from '../SelectionBox/SelectionBox';
import { faUserGraduate, faUsers } from '@fortawesome/free-solid-svg-icons'
// import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons'

function UserTypeSelectors(props) {
  const menteeRequirements = [
  ]

  const mentorRequirements = [
  ]

  // const donorRequirements = [
  // ]

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch' }}>
      <SelectionBox title="Mentee" icon={faUserGraduate} requirements={menteeRequirements} linkto="/menteeonboarding">
        <span>A student or recent graduate looking for a Mentor<br/></span> 
        { props.isTern && <div><br/>Undergraduate students may access TERN Mentoring as Mentees for up to two years after their graduation date.</div> }
      </SelectionBox>
      <SelectionBox title="Mentor" icon={faUsers} requirements={mentorRequirements} linkto="/mentoronboarding">
        <span>A professional looking to help a student or recent graduate.<br/></span>
        { props.isTern && <div><br/>Mentors can include JMU alumni &amp; parents, JMU employees, prospective JMU employers, friends of JMU, and fans of mentorship.</div> }
      </SelectionBox>
      {/* <SelectionBox title="Donor" description="Donate to TERN Mentoring or an educational scholarship"
        icon={faHandHoldingUsd} requirements={donorRequirements} /> */}
    </div>
  )

}

export default UserTypeSelectors;