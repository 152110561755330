import { useState } from 'react';
import { useStore } from '../../store/Store';
import { Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { emptySchemaUI } from '../../schemas';
import { patchUser } from '../../lib/api';

import OnboardingWizardItem from '../OnboardingWizardItem/OnboardingWizardItem';

function MenteeGoals(props) {
  const {state, dispatch} = useStore();

  const [aspirations, setAspirations] = useState(state.user?.infoSheet?.careerInspiration || '')
  const [strength, setStrength] = useState(state.user?.infoSheet?.strength || '')
  const [toImprove, setToImprove] = useState(state.user?.infoSheet?.toImprove || '')


  const formParams = {
    resolver: joiResolver(emptySchemaUI),
  }

  const { handleSubmit } = useForm(formParams);



  async function onSubmit(/*data*/) {
    try {
      const updatedData = {
        careerAspiration: aspirations,
        strength: strength,
        toImprove: toImprove,
      }

      await patchUser(props.isMentor, state.user, updatedData, dispatch);

      props.nav.onNext();
    }
    catch (err) {
      console.log('Goals err', err);
    }

  }

  return(
    <OnboardingWizardItem title='Goals' description='Please describe your future goals and what you hope to achieve through the mentoring process.' handleSubmit={handleSubmit(onSubmit)} {...props.nav}>
      <div className="wizard-max-inner full-size">
        <Form.Field>
          <Form.Field.Label style={{textAlign: 'left'}}>
            <Form.Label> Career Aspirations</Form.Label>
          </Form.Field.Label>
          <Form.Field.Body >
            <Form.Textarea rows={2} value={aspirations} onChange={e => setAspirations(e.target.value)} placeholder="Describe your career aspirations." />
          </Form.Field.Body>
        </Form.Field>

        <Form.Field>
          <Form.Field.Label style={{textAlign: 'left'}}>
            <Form.Label> Strengths </Form.Label>
          </Form.Field.Label>
          <Form.Field.Body >
            <Form.Textarea rows={2} value={strength} onChange={e => setStrength(e.target.value)} placeholder="I excel at..." />
          </Form.Field.Body>
        </Form.Field>

        <Form.Field>
          <Form.Field.Label style={{textAlign: 'left'}}>
            <Form.Label>Would like to develop these skills</Form.Label>
          </Form.Field.Label>
          <Form.Field.Body >
            <Form.Textarea rows={2} value={toImprove} onChange={e => setToImprove(e.target.value)} placeholder="I'd like to improve at..." />
          </Form.Field.Body>
        </Form.Field>


      </div>
    </OnboardingWizardItem>
  )
}

export default MenteeGoals;