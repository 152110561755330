import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Columns, Heading, Container, Button } from 'react-bulma-components';
import { arrayToCsvCapitalized, formatPhone, notProvidedString, locationString } from '../../lib/stringHelpers.js';
import { useStore } from '../../store/Store';
import { getUser, patchUser, removeProfilePic } from '../../lib/api';
import { Form } from 'react-bulma-components';
import { parseISO, format } from 'date-fns';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture.js';
import ProfilePictureUploaderModal from '../../components/ProfilePictureUploadModal/ProfilePictureUploadModal';
import CreateProfileDetailsModal from '../../components/CreateProfileDetails/CreateProfileDetailsModal.js';

function ProfilePage() {
  const history = useHistory();
  const { state, dispatch } = useStore();
  const [ showProfileDialog, setShowProfileDialog ] = useState(false);
  const [ showContactInfoModal, setShowContactInfoModal ] = useState(false);
  const [ doNotEmail, setDoNotEmail ] = useState(state.user?.doNotEmail);
  const [ doNotSms, setDoNotSms ] = useState(state.user?.doNotSms);

  //const isMentor = state.user.activeProfile.mentorId > 0;
  const isMentor = state.user.activeProfile.contactType.id === 2 && state.user.activeProfile.mentorId > 0;

  const profileInfoLink = '/onboarding?r=profile';
  // const profileInfoLink = (state.user.groupId !== 1 && '/onboarding?r=profile') || (isMentor ? '/mentorprofileinfo?r=profile' : '/menteeprofileinfo?r=profile');
  const infoSheetLink = state.user.groupId === 1 ? '/infosheet?r=profile' : '/onboarding?r=profile';

  async function onProfileSaved() {
    try {
      let userData = await getUser(state.user.contactId);
      dispatch( { type: 'SET_USER', payload: userData });
    }
    catch (err) {
      console.error('Error refreshing profile');
    }
    finally {
      setShowProfileDialog(false);
    }
  }

  async function updateEmailNotifications(newValue) {
    try {
      const updatedData = {
        doNotEmail: newValue,
      }

      setDoNotEmail(newValue);
      await patchUser(isMentor, state.user, updatedData, dispatch);
    }
    catch (err) {
      console.log('updateEmailNotifications err', err);
    }
  }

  async function updateSmsNotifications(newValue) {
    try {
      const updatedData = {
        doNotSms: newValue,
      }

      setDoNotSms(newValue);
      await patchUser(isMentor, state.user, updatedData, dispatch);
    }
    catch (err) {
      console.log('updateSmsNotifications err', err);
    }
  }

  async function removeProfilePicture() {
    if (window.confirm('Are you sure you want to remove your profile picture?')) {
      await removeProfilePic();
      onProfileSaved();
    }
  }

  return (
    <Container alignContent="center" className="profile-page container is-fullwidth px-3" style={{ paddingTop: '3.25rem', paddingBottom: '8rem' }}>

      <Columns>
        <Columns.Column className="is-one-third">
          <Heading size={4}>Profile Picture</Heading>
          <Heading subtitle>
            You can change or remove your profile picture here
          </Heading>

          <Button.Group>
            <Button className="button is-info is-outlined" onClick={() => setShowProfileDialog(true)}>Change</Button>
            <Button className="button is-danger is-outlined" onClick={() => removeProfilePicture()}>Remove</Button>
          </Button.Group>
        </Columns.Column>
        <Columns.Column>
          <div className="is-flex is-justify-content-center" >
              <div style={{ height: 300, width: 300 }}>
                <ProfilePicture useEmptyProfilePic={true} picture={state.user.profilePic} />
              </div>
          </div>

        </Columns.Column>
      </Columns>

      <hr />

      <Columns>
        <Columns.Column className="is-one-third">
          <Heading size={4}>Contact Information</Heading>
          <Heading subtitle>
            Information on how to get in touch with you
          </Heading>
          <button className="button is-info is-outlined" onClick={() => setShowContactInfoModal(true)}>Change</button>

          {/* <button className="button is-info is-outlined">Change</button> */}
        </Columns.Column>

        <Columns.Column>
          <div className="info-item">
            <label className="label">Name</label> {state.user.firstName} {state.user.lastName}
          </div>
          <div className="info-item">
            <label className="label">Email</label> {state.user.email}
          </div>
          <div className="info-item">
            <label className="label">Phone</label> {formatPhone(state.user.phoneNumber)}
          </div>
          <div className="info-item">
            <label className="label">Zip Code</label> {state.user.zipCode}
          </div>
          <div className="info-item">
            <label className="label">Birthdate</label> { state.user.birthDate === null ? notProvidedString('') : format(parseISO(state.user.birthDate), 'PP')}
          </div>

          {isMentor &&
            <div className="info-item">
              <label className="label">Work Company</label> {notProvidedString(state.user.workCompany)}
            </div>
          } 

        </Columns.Column>
      </Columns>

      <hr />

      {state.user.group.id === 1 ?
        <>
          <Columns>
            <Columns.Column className="is-one-third">
              <Heading size={4}>Personal Information</Heading>
              <Heading subtitle>
                Information regarding your personal and educational details
              </Heading>

              <button className="button is-info is-outlined" onClick={() => history.push(profileInfoLink)}>Change</button>
            </Columns.Column>
            
            <Columns.Column>
              {isMentor &&
                <div className="info-item">
                  <label className="label">Professional Roles</label> {state.user?.mentorRoles ? notProvidedString(arrayToCsvCapitalized(state.user.mentorRoles.map(e => e.description), state.user.infoSheet.otherRole)) : notProvidedString('')  }
                </div>
              }

              <div className="info-item">
                <label className="label">University / Alma Mater</label> {notProvidedString(state.user.education.university)}
              </div>
              <div className="info-item">
                <label className="label">Graduation Date</label> {state.user.education.graduationDate ? format(parseISO(state.user.education.graduationDate), 'MMMM yyyy') : notProvidedString('')}
              </div>
              <div className="info-item">
                <label className="label">Majors</label> <div>{state.user.education.majorDegrees ? state.user.education.majorDegrees.map((degree, idx) => <div key={`majordegree_${idx}`}>{degree}</div>)  : notProvidedString('') }</div>
              </div>
              <div className="info-item">
                <label className="label">Minors</label> <div>{state.user.education.minorDegrees ? state.user.education.minorDegrees.map((degree, idx) => <div key={`minordegree_${idx}`}>{degree}</div>) : notProvidedString('') }</div>
              </div>
              <div className="info-item">
                <label className="label">Advanced Degrees</label> <div>{state.user.education.advancedDegrees ? state.user.education.advancedDegrees.map((degree, idx) => <div key={`advdegree_${idx}`}>{degree}</div>) : notProvidedString('') }</div>
              </div>
              <div className="info-item">
                <label className="label">Clubs, Organizations, Networks and Affinity Groups</label> {notProvidedString(arrayToCsvCapitalized(state.user.infoSheet.affinityGroups.map(e => e.description), state.user.infoSheet.otherAffinityGroup))}
              </div>
              <div className="info-item">
                <label className="label">Identity Preferences</label> {notProvidedString(arrayToCsvCapitalized([...state.user.identities.map(m => m.description), state.user.infoSheet.otherGender, state.user.infoSheet.otherRace]))}
              </div>
              <div className="info-item">
                <label className="label">Preferred Pronouns</label> {notProvidedString(state.user.preferredPronouns)}
              </div>
              <div className="info-item">
                <label className="label">Languages</label> {notProvidedString(arrayToCsvCapitalized(state.user.infoSheet.languages.map(m => m.description)))}
              </div>
            </Columns.Column>
          </Columns>

          <hr />

          <Columns>
            <Columns.Column className="is-one-third">
              <Heading size={4}>Social Media</Heading>
              <Heading subtitle>
                Information regarding your Social Media accounts and/or websites you'd like to share
              </Heading>

              <button className="button is-info is-outlined" onClick={() => history.push(profileInfoLink)}>Change</button>
            </Columns.Column>

            <Columns.Column>

              <div className="info-item">
                <label className="label">LinkedIn</label> {notProvidedString(state.user.linkedinProfile)}
              </div>
              <div className="info-item">
                <label className="label">Instagram</label> {notProvidedString(state.user.instagram)}
              </div>
              <div className="info-item">
                <label className="label">Twitter</label> {notProvidedString(state.user.twitter)}
              </div>
              <div className="info-item">
                <label className="label">Work Website</label> {notProvidedString(state.user.workWebsite)}
              </div>
              <div className="info-item">
                <label className="label">Personal Website</label> {notProvidedString(state.user.personalWebsite)}
              </div>
            </Columns.Column>
          </Columns>

          <hr />

          <Columns>
            <Columns.Column className="is-one-third">
              <Heading size={4}>Additional Information</Heading>
              <Heading subtitle>
                Additional information used in matching
              </Heading>

              <button className="button is-info is-outlined" onClick={() => history.push(infoSheetLink)}>Change</button>
            </Columns.Column>

            <Columns.Column>

              <div className="info-item">
                <label className="label">Personality Test Info</label> {notProvidedString(state.user.infoSheet.personalityTestText)}
              </div>
              <div className="info-item">
                <label className="label">Personal Interests</label> {notProvidedString(arrayToCsvCapitalized(state.user.infoSheet.personalInterests.map(e => e.description), state.user.infoSheet.otherPersonalInterest))}
              </div>

              <div className="info-item">
                <label className="label">Business Interests</label> {notProvidedString(arrayToCsvCapitalized(state.user.infoSheet.businessInterests.map(e => e.description), state.user.infoSheet.otherBusinessInterest))}
              </div>

              <div className="info-item">
                <label className="label">Hometown</label> {notProvidedString(locationString(state.user.infoSheet.homeTown, state.user.infoSheet.homeState, state.user.infoSheet.homeCountry))}
              </div>
              <div className="info-item">
                <label className="label">Where You Live</label> {notProvidedString(locationString(state.user.infoSheet.currentCity, state.user.infoSheet.currentState, state.user.infoSheet.currentCountry))}          
              </div>
              <div className="info-item">
                <label className="label">Where You Work</label> {notProvidedString(locationString(state.user.infoSheet.workCity, state.user.infoSheet.workState, state.user.infoSheet.workCountry))}
              </div>

              {!isMentor &&
                <>
                  <div className="info-item">
                    <label className="label">Career Aspirations</label> {notProvidedString(state.user.infoSheet.careerInspiration)}
                  </div>
                  <div className="info-item">
                    <label className="label">Strengths</label> {notProvidedString(state.user.infoSheet.strength)}
                  </div>
                  <div className="info-item">
                    <label className="label">Would like to develop these skills</label> {notProvidedString(state.user.infoSheet.toImprove)}
                  </div>
                </>
              }

              <div className="info-item">
                <label className="label">My goal(s) for connecting to a Mentee through TERN Mentoring is/are...</label> {notProvidedString(state.user.infoSheet.goalsStatement)}
              </div>
              <div className="info-item">
                <label className="label">Free Response/Additional Information</label> {notProvidedString(state.user.infoSheet.additionalInfo)}
              </div>

              <div className="info-item">
                <label className="label">Recommendations</label> <div>{(state.user.infoSheet.recommendations && state.user.infoSheet.recommendations.length > 0) ? state.user.infoSheet.recommendations.map((m, idx) => <div key={`rec_${idx}`}>{m}</div>) : 'None provided'}</div>
              </div>

            </Columns.Column>
          </Columns>
          
          <hr />
        </>
      :
        <>
          <Columns>
            <Columns.Column className="is-one-third">
              <Heading size={4}>Survey Information</Heading>
              <Heading subtitle>
                Information about your survey status and answers
              </Heading>

              <button className="button is-info is-outlined" onClick={() => history.push('/onboarding')}>Change</button>
            </Columns.Column>
            
            <Columns.Column>
              {state.user.wizard1Page === 99 &&
                <div className="info-item">
                  <label className="label">Questionnaire Status </label>
                  You've completed all the necessary survey questions.  You can change your answers by clicking the Change button.
                </div>
              }

              {state.user.wizard1Page !== 99 &&
                <div className="info-item">
                  <label className="label">Questionnaire Status </label>
                  You have <b>not</b> completed all the necessary survey questions.  Click the Change button to complete the questionnaire.
                </div>
              }
            </Columns.Column>
          </Columns>

          <hr />
        </> 
      }

      <Columns>
        <Columns.Column className="is-one-third">
          <Heading size={4}>Notifications</Heading>
          <Heading subtitle>
            Decide how you want to be notified
          </Heading>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <input id="sendEmails" type="checkbox" name="sendEmails" className="switch" checked={!doNotEmail} onChange={e => updateEmailNotifications(!e.target.checked)} />
            <label htmlFor="sendEmails">Send me email notifications</label>
          </Form.Field>
          <Form.Field>
            <input id="sendSms" type="checkbox" name="sendSms" className="switch" checked={!doNotSms} onChange={e => updateSmsNotifications(!e.target.checked)} />
            <label htmlFor="sendSms">Send me SMS notifications</label>
          </Form.Field>
        </Columns.Column>
      </Columns>

      <ProfilePictureUploaderModal show={showProfileDialog} onClose={() => setShowProfileDialog(false)} onCancel={() => setShowProfileDialog(false)} onSaved={onProfileSaved} />
      <CreateProfileDetailsModal user={state.user} isMentor={isMentor} open={showContactInfoModal} onClose={() => setShowContactInfoModal(false)} onCancel={() => setShowContactInfoModal(false)} onSaved={() => setShowContactInfoModal(false)}/>

    </Container>

  )

}

export default ProfilePage;