// TODO: remove this section
import { useStore } from '../../store/Store';
import { useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { useHistory } from 'react-router-dom';

import { patchUser, createUser } from '../../lib/api';

import OnboardingWizardItem from '../OnboardingWizardItem/OnboardingWizardItem';

import ContactInfoForm from '../ContactInfoForm/ContactInfoForm';


function CreateProfileDetails(props) {
  const { user: cognitoUser } = useAuthenticator((context) => [context.route]);
  const history = useHistory();
  const { state, dispatch } = useStore();
  const [ doSubmit, setDoSubmit] = useState();

  const navProps = {
    ...props.nav,
    onFinish: () => setDoSubmit(true),
  }

  const initialUserData = {
    firstName: state.user?.firstName,
    lastName: state.user?.lastName,
    email: cognitoUser?.attributes?.email,
    phoneNumber: (cognitoUser && cognitoUser.attributes['phone_number']) ? cognitoUser.attributes['phone_number'].substring(2) : state.user?.phoneNumber,
    zipCode: state.user?.zipCode,
    linkedinProfile: state.user?.linkedinProfile,
    workCompany: state.user?.workCompany,
    birthDate: state.user?.birthDate,
  }

  async function onSubmit(formData) {
    // Reset the validation
    setDoSubmit(false);
    console.log('data', formData);

    if (formData.valid) {
      
      try {
        const newUserData = {
          ...formData.data,
          birthDate: formData.data.birthDate.toISOString()
        }

        let theUser = state.user;

        if (!theUser) {
          theUser = await createUser(props.isMentor, cognitoUser.username, { ...newUserData, signupCode: cognitoUser.attributes['custom:signupcode']} );
        }

        // eslint-disable-next-line no-unused-vars
        const patchedUserData = await patchUser(props.isMentor, theUser, newUserData, dispatch);

        history.push('/onboarding');

        // if (theUser.groupId !== 1) {
        //   history.push('/');
        // }
        // else if (props.isMentor) {
        //   console.log('patchedUserData', patchedUserData)
        //   if (!patchedUserData.subscription) {
        //     history.push('/payment');
        //   }
        //   else {
        //     history.push('/');
        //   }
        // }
        // else {
        //   history.push('/menteeprofileinfo');
        // }      
      }
      catch (err) {
        console.log('CreateProfileDetails err', err);
      }
    }

    return false;
  }

  return (
    <OnboardingWizardItem title="Contact Information" description="Let's start by getting your contact details" {...navProps}>
      <ContactInfoForm user={initialUserData} isMentor={props.isMentor} doSubmit={doSubmit} handleOnSubmitResult={onSubmit}/>
    </OnboardingWizardItem>

  )
}

export default CreateProfileDetails;