import {Multiselect} from 'multiselect-react-dropdown';
import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useStore } from '../../store/Store';
import { Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { emptySchemaUI } from '../../schemas';
import { patchUser } from '../../lib/api';
import OnboardingWizardItem from '../OnboardingWizardItem/OnboardingWizardItem';

function LanguagePage (props) {
  const history = useHistory();
  const { state, dispatch } = useStore();
  const [selectedLanguages, setSelectedLanguages] = useState(state.user?.infoSheet?.languages || null);
  const redirectLink = new URLSearchParams(useLocation().search).get('r') || '';

  const languages = [...state.staticValues.languages];

  const languageArray = selectedLanguages.map(({id, description}) => (id)); 

  const formParams = {
    resolver: joiResolver(emptySchemaUI),
  }

  const { handleSubmit } = useForm(formParams);

  async function onSubmit(/*data*/) {
    try {
      const updatedData = {
        languages: languageArray
      }

      await patchUser(props.isMentor, state.user, updatedData, dispatch);

      if (redirectLink) {
        history.push('/' + redirectLink);
      }
      else {
        props.nav.onNext();
      }
    }
    catch (err) {
      console.log('Language err', err);
    }

  }

  return (
  
  <OnboardingWizardItem title = 'Language' description = 'Please select every language you speak fluently.' handleSubmit={handleSubmit(onSubmit)} {...props.nav}>
      <div className="wizard-max-inner">

        <Form.Field className="side-by-side-bulma">

          <Form.Field className="is-normal">
            <Multiselect 
            options = {languages}
            selectedValues={selectedLanguages}
            onSelect={setSelectedLanguages}
            displayValue="description"
            showCheckbox = {true}
            avoidHighlightFirstOption = {true}
            hidePlaceholder = {true}
            showArrow ={true}

            placeholder="Select..."

            style={{
              chips: {
                background: '#3e8ed0'
              }
            }}
          />
          </Form.Field>
        </Form.Field>
      </div>      
    </OnboardingWizardItem>
  )
}

export default LanguagePage;