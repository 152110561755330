import { useState } from 'react';
import { Form, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

// All props are passed to the underlying Button component 

// props.values: An array of strings to transform into input boxes and their initial value
// props.onChange: Returns a new array of strings with the input boxes values
// props.noEmpty: Do not display an empty, default field when prop.values is empty or null (default: false)
// props.fullWidth: Extend to take the full width of the container (default: true)
// props.inputProps: React props/component options to padd to the Input component
// props.keyPrefix: The string to put in front of the input element Keys
// Children is the content of the button 
// e.g. <MultiInput value={['Val1', 'Val2']} onChange={(newValues) => console.log(newValues)}>Add Value</MultiInput>

function MultiInput(props) {
  const [ values, setValues ] = useState(getInitialState());
  const inputProps = props.inputProps ? props.inputProps : null;

  const keyPrefix = props.key || 'multi_input_';

  function getInitialState() {
    let initValues = props.values ? [...props.values] : [];
    if (initValues.length === 0 && props.noEmpty !== true) {
      initValues[0] = '';
    }

    return initValues;
  }

  function handleOnChange(idx, e) {
    values[idx] = e.target.value;
    setNewValues([...values]);
  }

  function handleOnRemove(idx) {
    values.splice(idx, 1);
    setNewValues([...values]);
  }

  function addInputField() {
    setNewValues([...values, '']);
  }

  function setNewValues(newValues) {
    setValues(newValues);

    console.log(newValues)
    if (props.onChange) {
      props.onChange(newValues.filter(e => e !== ''));
    }
  }
  
  function getInputFields() {

    if (values) {
      return values.map((theValue, idx) => 
        <Form.Field className="has-addons">
          <Form.Control className={`${props.fullWidth !== false ? 'is-expanded' : ''}`}>
            <Form.Input key={`${keyPrefix}_${idx}`} {...inputProps} value={theValue} onChange={(e)=> handleOnChange(idx, e)} />
          </Form.Control>
          <Form.Control>
            <Button className="is-info" disabled={!props.noEmpty && values.length === 1} onClick={() => handleOnRemove(idx)}><FontAwesomeIcon icon={faWindowClose}/></Button>
          </Form.Control>
        </Form.Field>)
    }

  }

  return (<>
      { getInputFields() }
      <Button type="button" color={props.color} colorVariant={props.colorVariant} onClick={addInputField} >{ props.children } </Button>
    </>
  )
}

export default MultiInput;