import { useEffect, useState } from 'react';

import { Columns, Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { contactUI as contactSchema } from '../../schemas';
import { differenceInYears } from 'date-fns';
import { monthNames, getNumberArray, registerFormComponents, controlColor, datePartsToDate, dateToDateParts } from '../../lib/formHelpers';

const years = getNumberArray((new Date()).getFullYear() - 18, 100, true);
const days = getNumberArray(1, 31);

function ContactInfoForm({user, isMentor, doSubmit, handleOnSubmitResult}) {
  // const [ birthdate, setBirthdate ] = useState({ month: 6, day: 6, year: 1976 })
  const [ birthdate, setBirthdate ] = useState(user?.birthDate ? dateToDateParts(user.birthDate) : { month: 'Month', day: 'Day', year: 'Year' })
  const [ birthdateError, setBirthdateError ] = useState('')

  const formParams = {
    resolver: joiResolver(contactSchema),
    defaultValues: { ...user },
  }

  // Don't do this check here, do it in the route
  // Otherwise it'll run during onboarding and prematurely redirect out of the wizard
  // useEffect(() => {
  //   if (user) {
  //     history.push('/');
  //   }
  // }, []) 

  const yearOptions = ['Year', ...years];
  const dayOptions = ['Day', ...days];
  const monthOptions = ['Month', ...monthNames];

  const { register, handleSubmit, formState: { errors } } = useForm(formParams);
  const controlledInput = registerFormComponents(register, errors);

  useEffect(() => {

    if (doSubmit) {
      console.log('validate');

      if (handleOnSubmitResult) {
        let birthdateValid = false;
        try {
          const birthday = datePartsToDate(birthdate);
          setBirthdateError('');
    
          const age = differenceInYears(new Date(), birthday);
          if (age > 17) {
            birthdateValid = true;
          }
          else {
            setBirthdateError('You must be 18 or older');
          }
        } 
        catch (err) {
          setBirthdateError('Required');
          console.error('Bad birthday', err);
        }

        handleSubmit((data) => {
          handleOnSubmitResult({ valid: birthdateValid, data: {...data, birthDate: datePartsToDate(birthdate)}});
        },
        (err) => {
          handleOnSubmitResult({ valid: false, errors: err});
        })();
      }
    }
  }, [doSubmit, handleSubmit, handleOnSubmitResult, birthdate]);

  return (<>
      <Columns>
        {/* First Name */}
        <Columns.Column>
          <Form.Field>
            <Form.Label>First name</Form.Label>
            {controlledInput('firstName')}
          </Form.Field>
        </Columns.Column>

        {/* Last Name */}
        <Columns.Column>
          <Form.Field>
            <Form.Label>Last name</Form.Label>
            {controlledInput('lastName')}
          </Form.Field>
        </Columns.Column>
      </Columns>

      {/* Email */}
      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Email</Form.Label>
            {controlledInput('email', 'input', { includeHelp: false, disabled: true })}
            <Form.Help color={controlColor(errors, 'email')}>{errors?.email?.message ? errors?.email?.message : "This is your login"}</Form.Help>
          </Form.Field>
        </Columns.Column>

        {/* LinkedIn Profile */}
        {/* <Columns.Column>
          <Form.Field>
            <Form.Label>LinkedIn Profile</Form.Label>
            {controlledInput('linkedinProfile', 'input', { placeholder: 'Optional'})}
          </Form.Field>
        </Columns.Column> */}

        { isMentor && 
          <Columns.Column>
            <Form.Field>
              <Form.Label>Company Name</Form.Label>
              {controlledInput('workCompany', 'input', { placeholder: 'Optional'})}
            </Form.Field>
          </Columns.Column>
        }
      </Columns>

      {/* Birthdate */}
      <Columns>
        <Columns.Column>
          <Form.Field className="is-grouped">
            <Form.Field className="side-by-side-bulma">
              <Form.Label>Birthdate</Form.Label>
              <Form.Control>
                <Form.Select value={birthdate.month} color={birthdateError ? 'danger': ''} onChange={e => setBirthdate({...birthdate, month: e.target.value})} >
                  {monthOptions.map((month, idx) =>
                    <option key={`birthdate_month_${month}`} value={idx}>{month}</option>
                  )}
                </Form.Select>
              </Form.Control>
              <Form.Help color="danger">{birthdateError}</Form.Help>
            </Form.Field>

            <Form.Field className="side-by-side-bulma">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control>
                <Form.Select value={birthdate.day} color={birthdateError ? 'danger': ''} onChange={e => setBirthdate({...birthdate, day: e.target.value})} >
                  {dayOptions.map((day) =>
                    <option key={`birthdate_day_${day}`} value={day}>{day}</option>
                  )}
                </Form.Select>
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control>
                <Form.Select value={birthdate.year} color={birthdateError ? 'danger': ''} onChange={e => setBirthdate({...birthdate, year: e.target.value})} >
                  {yearOptions.map((year) =>
                    <option key={`birthdate_year_${year}`} value={year}>{year}</option>
                  )}
                </Form.Select>
              </Form.Control>
            </Form.Field>
          </Form.Field>
        </Columns.Column>

        {/* phoneNumber */}
        <Columns.Column>
          <Form.Field>
            <Form.Label>Phone</Form.Label>
            {controlledInput('phoneNumber')}
          </Form.Field>
        </Columns.Column>

        {/* zipCode */}
        <Columns.Column>
          <Form.Field>
            <Form.Label>Zip Code</Form.Label>
            {controlledInput('zipCode')}
          </Form.Field>
        </Columns.Column>
      </Columns>

    </>

  )
}

export default ContactInfoForm;