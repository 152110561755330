import { format, parseISO, isDate } from 'date-fns';
import { capitalCase } from "capital-case";

export function boolString (value) {
  return value ? 'Yes' : 'No';
}

export function formatPhone (phoneNumber) {
  if (phoneNumber && phoneNumber.length >= 10) {
    return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
  }
  
  return phoneNumber;
}

export function notProvidedString (value) {
  return value || 'Not provided';
}

export function defaultDateTimeString (value) {
  let theDate = value;
  if (!isDate(theDate)) {
    theDate = parseISO(theDate);
  }
  return format(theDate, 'PPpp');
}

export function defaultDateString (value) {
  let theDate = value;
  if (!isDate(theDate)) {
    theDate = parseISO(theDate);
  }
  return format(theDate, 'PP');
}

export function capitalize (value) {
  return capitalCase(value);
}

export function arrayToCsvCapitalized(theArray, other) {
  let initialString = '';
  let finalString = '';

  if (theArray) {
    let arrayString = theArray.reduce((previousValue, currentValue) => 
      currentValue ? `${previousValue}, ${(currentValue && currentValue[0].toUpperCase()) + currentValue.slice(1)}` : previousValue, initialString);
    
      finalString = arrayString.substring(1, arrayString.length);
  }

  if (other) {
    finalString = finalString ? `${finalString}, ${other}` : other;
  }

  return finalString;
}

export function locationString(city, state, country) {
  let parts = [ city, state, country ];
  let theString = '';
  parts.forEach((part) => {
    if (part) {
      theString = theString ? `${theString}, ${part.trim()}` : part.trim();
    }
  });
  return theString;
}