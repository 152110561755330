import { useEffect } from 'react';
import { Modal } from 'react-bulma-components';
import { Heading } from 'react-bulma-components';

import confetti from 'canvas-confetti';

function MentorshipCompleteModal(props) {

  useEffect(() => {
    confetti();
  }, [])

  return (
    <Modal showClose={false} show={props.show} onClose={props.onClose}>
      <Modal.Card>
        <Modal.Card.Body className="has-text-centered">
          <Heading>Congratulations!</Heading>
          <Heading size={4}>You've completed your Mentorship!</Heading>
        </Modal.Card.Body>
        <Modal.Card.Footer className="modal-card-foot is-flex is-justify-content-center">
          <button className="button is-success" onClick={props.onClose}>Awesome!</button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  );
};

export default MentorshipCompleteModal;
