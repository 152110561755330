import './TodoItem.scss';
import classNames from 'classnames';
import { Heading } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faQuestion, faCog, faCheck, faLockOpen, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

function TodoItem(props) {

  // Determine the icon
  let icon = faQuestion;
  if (props.completed) {
    icon = faCheck;
  }
  else if (props.locked) {
    icon = faLock;
  } 
  else if (props.unlocked) {
    icon = faLockOpen;
  }
  else if (props.inProgress) {
    icon = faCog;
  }

  // Determine the icon color
  let color = 'has-text-grey-lighter';
  if (props.completed) {
    color = 'has-text-success';
  } 
  else if (props.unlocked) {
    color = 'has-text-warning';
  }
  else if (props.inProgress) {
    color = 'orange';
  }

  // Stuff them into class arrays
  const iconHolderClassNames = classNames('todoitem-icon', color);
  const iconClassNames = classNames('fa-layers', 'fa-fw', { 'fa-spin': props.inProgress });

  function callOnClick(e) {
    if (props.onClick) {
      e.preventDefault();
      props.onClick(e)
    }
  }

  return (
    <div className="todoitem">
      <div className={iconHolderClassNames}>
        <span className={iconClassNames}>
          <FontAwesomeIcon icon={faCircle} /> 
          <FontAwesomeIcon color="white" icon={icon} transform={'shrink-8'}/>
        </span>
      </div>

      <div className="todoitem-content">
        { props.linkTo && !props.locked ? 
          <Heading size="5"><Link to={props.linkTo}>{props.title}</Link></Heading>
        :
        props.onClick && !props.locked ?
          <Heading size="5"><a href="#" onClick={callOnClick}>{props.title}</a></Heading>
        :
          <Heading size="5">{props.title}</Heading>
        }
        <Heading subtitle size="6">{props.description}</Heading>
      </div>
    </div>
  )
}

export default TodoItem;