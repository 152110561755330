import { useState } from 'react';
import { useStore } from '../../../store/Store';
import { emptySchemaUI } from '../../../schemas';
import { patchUser } from '../../../lib/api';
import { Columns, Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { countries, states as statesUSA, provinces } from '../../../lib/localization.js';
import OnboardingWizardItem from '../../OnboardingWizardItem/OnboardingWizardItem';


function InfoSheetLocation(props) {
  const { state, dispatch } = useStore();

  const [hometown, setHometown] = useState(state.user?.infoSheet?.homeTown || null);
  const [country, setCountry] = useState(state.user?.infoSheet?.hometownCountry || 'United States');
  const [homestateNa, setHomestateNa] = useState(state.user?.infoSheet?.hometownState || 'Virginia');
  const [homestateInt, setHomestateInt] = useState(state.user?.infoSheet?.hometownState || null)

  const [currentCity, setCurrentCity] = useState(state.user?.infoSheet?.currentCity || null);
  const [currentStateNa, setCurrentStateNa] = useState(state.user?.infoSheet?.currentState || null);
  const [currentStateInt, setCurrentStateInt] = useState(state.user?.infoSheet?.currentState || null);
  const [currentCountry, setCurrentCountry] = useState(state.user?.infoSheet?.currentCountry || null);

  const [workCompany, setWorkCompany] = useState(state.user?.infoSheet?.workCompany || null);
  const [workCity, setWorkCity] = useState(state.user?.infoSheet?.workCity || null);
  const [workStateNa, setWorkStateNa] = useState(state.user?.infoSheet?.workState || null);
  const [workStateInt, setWorkStateInt] = useState(state.user?.infoSheet?.workState || null);
  const [workCountry, setWorkCountry] = useState(state.user?.infoSheet?.workCountry || null);

  const countryOptions = countries;

  const provinceOptions = provinces;

  const stateOptions = statesUSA;

  const formParams = {
    resolver: joiResolver(emptySchemaUI),
  }
  const { handleSubmit } = useForm(formParams);

  function checkCountry(country, northam, international) {
    if (country === "United States" || country === "Canada" || country === null) {
      return northam;

    }

    else {
      return international;

    }

  }



  async function onSubmit(/*data*/) {

    try {
      const updatedData = {
        homeTown: hometown,
        hometownCountry: country,
        hometownState: checkCountry(country, homestateNa, homestateInt),
        currentCity: currentCity,
        currentCountry: currentCountry,
        currentState: checkCountry(currentCountry, currentStateNa, currentStateInt),
        workCity: workCity,
        workCompany: workCompany,
        workState: checkCountry(workCountry, workStateNa, workStateInt),
        workCountry: workCountry,
      }

      await patchUser(props.isMentor, state.user, updatedData, dispatch);

      props.nav.onNext();
    }
    catch (err) {
      console.error('Business Interests err:', err);
    }
  }

  return (
    <OnboardingWizardItem title="Location" description="This may allow us to match you based on geography." handleSubmit={handleSubmit(onSubmit)} {...props.nav}>


      <Columns className='is-flex' gap='is-0'>

        <Columns.Column className='is-one-third' >
          <Form.Field className="side-by-side-bulma">
            <Form.Label>Hometown</Form.Label>
            <Form.Input value={hometown} onChange={e => setHometown(e.target.value)} className="is-normal" placeholder="City" />
          </Form.Field>
        </Columns.Column>

        <Columns.Column className='is-one-third'>
          <Form.Field className="side-by-side-bulma" >
            <Form.Label>&nbsp;</Form.Label>
            <Form.Select value={country} onChange={e => setCountry(e.target.value)} placeholder='State' >
              <option key="countryselect" value="Select" hidden>Country</option>
              {countryOptions.map((country) =>
                <option key={`country_${country.name}`} value={country.name}>{country.name}</option>)}
            </Form.Select>
          </Form.Field>
        </Columns.Column>

        <Columns.Column className='is-one-third'>
          <Form.Label> &nbsp; </Form.Label>
          <Form.Field>
            {(() => {
              if (country === "Canada") {
                return (
                  <Form.Field className="is-fullwidth" >
                    <Form.Select className="is-fullwidth" value={homestateNa} onChange={e => setHomestateNa(e.target.value)} placeholder="Province">
                      <option key="provselect" value="Select" hidden>Province</option>
                      {provinceOptions.map((province) =>
                        <option key={`state_${province.name}`} value={province.name}>{province.name}</option>)}
                    </Form.Select>
                  </Form.Field>
                )
              }
              else if (country === "United States") {
                return (
                  <Form.Field className="is-fullwidth" >
                    <Form.Select className="is-fullwidth" value={homestateNa} onChange={e => setHomestateNa(e.target.value)} placeholder="State">
                      <option key="stateselect" value="Select" hidden>State</option>
                      {stateOptions.map((state) =>
                        <option key={`state_${state.name}`} value={state.name}>{state.name}</option>)}
                    </Form.Select>
                  </Form.Field>



                )
              }

              else {
                return (
                  <Form.Field>
                    <Form.Input value={homestateInt} onChange={e => setHomestateInt(e.target.value)} className="is-normal" placeholder="Region" />
                  </Form.Field>
                )
              }
            })()}

          </Form.Field>

        </Columns.Column>

      </Columns >


      <Columns className='is-flex' gap='is-0'>
        <Columns.Column className="is-one-third">

          <Form.Field className="side-by-side-bulma"  >
            <Form.Label>Where You Live</Form.Label>
            <Form.Input value={currentCity} onChange={e => setCurrentCity(e.target.value)} className="is-normal" placeholder="City" />
          </Form.Field>

        </Columns.Column>

        <Columns.Column className='is-one-third'>
          <Form.Field className="side-by-side-bulma" >
            <Form.Label>&nbsp;</Form.Label>
            <Form.Select value={currentCountry} onChange={e => setCurrentCountry(e.target.value)} placeholder='State' >
              <option key="countryselect" value="Select" hidden>Country</option>
              {countryOptions.map((country) =>
                <option key={`country_${country.name}`} value={country.name}>{country.name}</option>)}
            </Form.Select>
          </Form.Field>
        </Columns.Column>

        <Columns.Column className='is-one-third'>
          <Form.Label> &nbsp; </Form.Label>
          <Form.Field>
            {(() => {
              if (currentCountry === "Canada") {
                return (
                  <Form.Field className="is-fullwidth" >
                    <Form.Select className="is-fullwidth" value={currentStateNa} onChange={e => setCurrentStateNa(e.target.value)} placeholder="Province">
                      <option key="provselect" value="Select" hidden>Province</option>
                      {provinceOptions.map((province) =>
                        <option key={`state_${province.name}`} value={province.name}>{province.name}</option>)}
                    </Form.Select>
                  </Form.Field>
                )
              }
              else if (currentCountry === "United States") {
                return (
                  <Form.Field className="is-fullwidth" >
                    <Form.Select className="is-fullwidth" value={currentStateNa} onChange={e => setCurrentStateNa(e.target.value)} placeholder="State">
                      <option key="stateselect" value="Select" hidden>State</option>
                      {stateOptions.map((state) =>
                        <option key={`state_${state.name}`} value={state.name}>{state.name}</option>)}
                    </Form.Select>
                  </Form.Field>



                )
              }

              else {
                return (
                  <Form.Field>
                    <Form.Input value={currentStateInt} onChange={e => setCurrentStateInt(e.target.value)} className="is-normal" placeholder="Region" />
                  </Form.Field>
                )
              }
            })()}

          </Form.Field>
        </Columns.Column>
      </Columns>



      {(() => {
        if (state?.user?.activeProfile?.contactType?.id === 1) {
          return (
            <Columns className='is-flex' gap='is-0'>

              <Columns.Column className="is-one-third">
                <Form.Field className="side-by-side-bulma"  >
                  <Form.Label>Ideal Workplace</Form.Label>
                  <Form.Input value={workCompany} onChange={e => setWorkCompany(e.target.value)} className="is-normal" placeholder="Target Company" />
                </Form.Field>
              </Columns.Column>

              <Columns.Column className="is-one-third">
                <Form.Field className="side-by-side-bulma" >
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Input value={workCity} onChange={e => setWorkCity(e.target.value)} className="is-normal" placeholder="City" />
                </Form.Field>
              </Columns.Column>

              <Columns.Column className="is-one-third">
                <Form.Field className="side-by-side-bulma"  >
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Select className="is-fullwidth" value={workStateNa} onChange={e => setWorkStateNa(e.target.value)} placeholder='State/Province' >
                    <option style={{ width: '100%' }} key="stateselect" value="Select" hidden> State</option>
                    {stateOptions.map((state) =>
                      <option key={`state_${state.name}`} value={state.name}>{state.name}</option>)}
                    <option key={`state_other`} value={"Other"}>{"Other"}</option>
                  </Form.Select>
                </Form.Field>
              </Columns.Column>
            </Columns>

          )
        }

        else if (state?.user?.activeProfile?.contactType?.id === 2) {
          return (
            <Columns className='is-flex' gap='is-0'>
              <Columns.Column className="is-one-third">
                <Form.Field className="side-by-side-bulma"  >
                  <Form.Label>Where You Work</Form.Label>
                  <Form.Input value={workCity} onChange={e => setWorkCity(e.target.value)} className="is-normal" placeholder="City" />
                </Form.Field>
              </Columns.Column>

              <Columns.Column className='is-one-third'>

                <Form.Field className="side-by-side-bulma" >
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Select value={workCountry} onChange={e => setWorkCountry(e.target.value)} placeholder='State' >
                    <option key="countryselect" value="Select" hidden>Country</option>
                    {countryOptions.map((country) =>
                      <option key={`country_${country.name}`} value={country.name}>{country.name}</option>)}
                  </Form.Select>
                </Form.Field>
              </Columns.Column>

              <Columns.Column className='is-one-third'>
                <Form.Label> &nbsp; </Form.Label>
                <Form.Field>

                  {(() => {
                    if (workCountry === "Canada") {
                      return (
                        <Form.Field className="is-fullwidth" >
                          <Form.Select className="is-fullwidth" value={workStateNa} onChange={e => setWorkStateNa(e.target.value)} placeholder="Province">
                            <option style={{ width: '100%' }} key="provselect" value="Select" hidden>Province</option>
                            {provinceOptions.map((province) =>
                              <option key={`state_${province.name}`} value={province.name}>{province.name}</option>)}
                          </Form.Select>
                        </Form.Field>
                      )
                    }
                    else if (workCountry === "United States") {
                      return (
                        <Form.Field className="is-fullwidth" >
                          <Form.Select className="is-fullwidth" value={workStateNa} onChange={e => setWorkStateNa(e.target.value)} placeholder="State">
                            <option key="stateselect" value="Select" hidden>State</option>
                            {stateOptions.map((state) =>
                              <option key={`state_${state.name}`} value={state.name}>{state.name}</option>)}
                          </Form.Select>
                        </Form.Field>



                      )
                    }

                    else {
                      return (
                        <Form.Field>
                          <Form.Input value={workStateInt} onChange={e => setWorkStateInt(e.target.value)} className="is-normal" placeholder="Region" />
                        </Form.Field>
                      )
                    }
                  })()}

                </Form.Field>
              </Columns.Column>
            </Columns>


          )
        }

      })()}




    </OnboardingWizardItem>

  )


}
export default InfoSheetLocation;