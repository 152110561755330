import { Container, Card } from 'react-bulma-components';
import { useStore } from '../../store/Store';

function OnboardingWizardItem(props) {
  const { state } = useStore();

  function getButtons() {
    let buttonComponents = [];

    if (props.isGettingStartedPage) {
      buttonComponents.push(
        <Card.Footer.Item key="StartButton">
          <button className="button is-info" type="button" onClick={() => props.onNext()}>Let's Get Started!</button>
        </Card.Footer.Item>
      )
    }
    else {
      if (props.canGoBack) {
        buttonComponents.push(
          <Card.Footer.Item key="BackButton">
            <button className="button is-info" type="button" onClick={() => props.onBack()}>Back</button>
          </Card.Footer.Item>
        )
      }

      if (props.canGoNext) {
        if (props.onNext && state.user.groupId !== 1) {
          buttonComponents.push(
            <Card.Footer.Item key="NextButton">
              <button className="button is-info" type="button" onClick={() => props.onNext()}>Next</button>
            </Card.Footer.Item>
          )
        }
        else {
          buttonComponents.push(
            <Card.Footer.Item key="NextButton">
              <input className="button is-info" type="submit" value="Next" />
            </Card.Footer.Item>
          )
        }
      }
      else {
        if (props.onFinish) {
          buttonComponents.push(
            <Card.Footer.Item key="FinishButton">
              <button className="button is-info" type="button" onClick={() => props.onFinish()}>{props.finishText || 'Finish'}</button>
            </Card.Footer.Item>
          )
        }
        else {
          buttonComponents.push(
            <Card.Footer.Item key="FinishButton">
              <input className="button is-info" type="submit" value={props.finishText || 'Finish'} />
            </Card.Footer.Item>
          )
        }
      }
    }

    return buttonComponents;
  }

  return (
    <Container>
      <form onSubmit={props.handleSubmit}>
      <Card>
        { props.title && 
          <Card.Header>
            <Card.Header.Title justifyContent="center">
              {props.title}
            </Card.Header.Title>
          </Card.Header>
        }

        <Card.Content>
          {props.description ? 
            <p className="has-text-centered pb-2">
              {props.description}
            </p>
          : null }

          {props.children}

        </Card.Content>

        <Card.Footer>
          { getButtons() }
        </Card.Footer>
      </Card>
      </form>
    </Container>
  )
}

export default OnboardingWizardItem;