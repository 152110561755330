import { useState } from 'react';
import { useStore } from '../../store/Store';
import { Form, Columns } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { educationInfoUI } from '../../schemas';
import { monthNames, getNumberArray, registerFormComponents, datePartsToDate, dateToDateParts } from '../../lib/formHelpers';
import { patchUser } from '../../lib/api';
import MultiInput from '../MultiInput/MultiInput';

import OnboardingWizardItem from '../OnboardingWizardItem/OnboardingWizardItem';

function CreateProfileEducationInfo(props) {
  const { state, dispatch } = useStore();

  const years = getNumberArray((new Date()).getFullYear() + 2, 80, true);
  const yearOptions = ['Year', ...years];
  const monthOptions = ['Month', ...monthNames];

  const [ gradDate, setGradDate ] = useState(getGradDateFromState());
  const [ gradDateError, setGradDateError ] = useState('')
  const [ minors, setMinors ] = useState(state.user?.education?.minorDegrees ? [...state.user.education.minorDegrees] : []);
  const [ advancedDegrees, setAdvancedDegrees ] = useState(state.user?.education?.advancedDegrees ? [...state.user.education.advancedDegrees] : []);


  function getGradDateFromState() {
    let gradDateObj = { month: 'Month', day: 1, year: 'Year' };

    let parsedDate = dateToDateParts(state.user?.education?.graduationDate);

    return parsedDate || gradDateObj;
  }

  const formParams = {
    resolver: joiResolver(educationInfoUI),
    defaultValues: {
      university: state.user?.education?.university || '',
      firstMajorDegree: state.user?.education?.majorDegrees?.length > 0 ? state.user.education.majorDegrees[0] : '',
      secondMajorDegree: state.user?.education?.majorDegrees?.length >= 2 ? state.user.education.majorDegrees[1] : '',
    }
  }


  const { register, handleSubmit, formState: { errors } } = useForm(formParams);
  const controlledInput = registerFormComponents(register, errors);

  async function onSubmit(data) {
    let dataValid = false;

    try {
      datePartsToDate(gradDate);
      setGradDateError('');
      dataValid = true;
    } 
    catch (err) {
      setGradDateError('Required');
      console.error('Bad birthday', err);
    }

    if (dataValid) {
      try {
        let graduationDateObj = datePartsToDate(gradDate);

        let majors = [
          data.firstMajorDegree
        ]

        if (data.secondMajorDegree) {
          majors.push(data.secondMajorDegree);
        }

        const updatedData = {
          university: data.university,
          graduationDate: graduationDateObj.toISOString(),
          majorDegrees: majors,
          minorDegrees: minors.filter(e => e && e !== ''),
          advancedDegrees: advancedDegrees.filter(e => e && e !== ''),
        }

        await patchUser(props.isMentor, state.user, updatedData, dispatch);

        props.nav.onNext();
      }
      catch (err) {
        console.log('Education Info err', err);
      }
    } 
  }

  return (
    <OnboardingWizardItem title="Education Info" description="Tell us more about your education" handleSubmit={handleSubmit(onSubmit)} {...props.nav}>

      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Label>University / Alma Mater</Form.Label>
            {controlledInput('university')}
          </Form.Field>
        </Columns.Column>

        <Columns.Column>
          <Form.Field className="is-grouped">
            <Form.Field className="side-by-side-bulma">
              <Form.Label>Graduation Date</Form.Label>
              <Form.Control>
                <Form.Select value={gradDate.month} color={gradDateError ? 'danger': ''} onChange={e => setGradDate({...gradDate, month: e.target.value})} >
                  {monthOptions.map((month, idx) =>
                    <option key={`gradmonth_${month}`} value={idx}>{month}</option>
                  )}
                </Form.Select>
              </Form.Control>
              <Form.Help color="danger">{gradDateError}</Form.Help>
            </Form.Field>

            <Form.Field>
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control>
                <Form.Select value={gradDate.year} color={gradDateError ? 'danger': ''} onChange={e => setGradDate({...gradDate, year: e.target.value})} >
                  {yearOptions.map((year) =>
                    <option key={`gradyear_${year}`} value={year}>{year}</option>
                  )}
                </Form.Select>
              </Form.Control>
            </Form.Field>
          </Form.Field>
        </Columns.Column>
      </Columns>

      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Major(s)</Form.Label>
            {controlledInput('firstMajorDegree', 'input', { placeholder: '1st major'})}
          </Form.Field>
        </Columns.Column>

        <Columns.Column>
          <Form.Field>
            <Form.Label>&nbsp;</Form.Label>
            {controlledInput('secondMajorDegree', 'input', { placeholder: '2nd major (Optional)'})}
          </Form.Field>
        </Columns.Column>

      </Columns>

      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Minor(s)</Form.Label>
            <MultiInput values={minors} noEmpty={true} onChange={newMinors => setMinors(newMinors)} color="info" colorVariant="light">Add a minor</MultiInput>
          </Form.Field>
        </Columns.Column>

        <Columns.Column>
          <Form.Field>
            <Form.Label>Advanced Degrees (Degree, University and Year)</Form.Label>
            <MultiInput values={advancedDegrees} noEmpty={true} onChange={newDegrees => setAdvancedDegrees(newDegrees)} color="info" colorVariant="light">Add a degree</MultiInput>
          </Form.Field>
        </Columns.Column>
      </Columns>


    </OnboardingWizardItem>
  )
}

export default CreateProfileEducationInfo;