import './SelectionBox.scss';

import { Link } from 'react-router-dom';
import { Block, Box, Heading, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

function SelectionBox(props) {

  const hasRequirementsClass = props.requirements && props.requirements.length ? 'has-requirements' : '';
  
  return (
    <Box className={`selection-box has-text-centered ${props.className}`} style={{marginBottom: 0}}>
      <div className="selection-box-expanding-content-wrapper" style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
        <div className="selection-box-expanding-content-wrapper" style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
          <Heading spaced={false}>{props.title}</Heading>
          <Heading subtitle className={`selection-box-expanding-content text-is-centered ${hasRequirementsClass}`}>
            {props.description}
            {props.children}
          </Heading>
            <Block>
              <FontAwesomeIcon className="has-text-info" style={{ fontSize: 64 }} icon={props.icon} />
            </Block>

            {props.requirements && props.requirements.length ?
              <Block className={`selection-box-requirements-section has-text-left ${hasRequirementsClass}`}>
                <div style={{ fontWeight: 'bold' }}>Requirements</div>

                <ul style={{ listStyleType: 'none', marginLeft: 10, marginTop: '0.5em' }}>
                  {props.requirements.map((req, idx) =>
                    <li key={idx}><FontAwesomeIcon className="has-text-info mr-4" icon={faCheckCircle} />{req}</li>
                  )}
                </ul>
              </Block>
              : null}
            { props.onSelect ? 
              <Button className="px-6 is-primary has-text-weight-bold" onClick={props.onSelect}>Select</Button>
            :
              <Link to={props.linkto} className="button px-6 is-primary has-text-weight-bold">Select</Link>
            }
        </div>
      </div>
    </Box>
  )

}

export default SelectionBox;