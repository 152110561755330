import { useStore } from '../../store/Store';
import { patchUser } from '../../lib/api';
import { Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { mentorRoleUI as roleUISchema } from '../../schemas';
import { alphaSort99 } from '../../lib/formHelpers';
import { useState } from 'react';

import OnboardingWizardItem from '../OnboardingWizardItem/OnboardingWizardItem';

function CreateProfileRole(props) {
  const { state, dispatch } = useStore();

  const roleOptions= [...state.staticValues.mentorRoles.sort(alphaSort99)];
  const [selectedRoles, setSelectedRoles] = useState(getInitialSelectedRoles());
  const [otherRole, setOtherRole] = useState(state.user.infoSheet.otherRole || '');

  function getInitialSelectedRoles() {
    if (state.user) {
      return state.user.mentorRoles.map((e) => {
        return e.id;
      })
    }
  }

  const formParams = {
    resolver: joiResolver(roleUISchema),
  }

  const { handleSubmit } = useForm(formParams);

  async function onSubmit(data) {

    try {
      const newData = {
        mentorRoles: selectedRoles,
        otherRole: otherRole,
      }
      
      await patchUser(props.isMentor, state.user, newData, dispatch);

      props.nav.onNext();
    }
    catch (err) {
      console.error('Role err', err);
    }

  }

  function toggleRole(theRole, hasRole) {
    if (hasRole) {
      setSelectedRoles([...selectedRoles, theRole]);
    }
    else {
      setSelectedRoles(selectedRoles.filter(e => e !== theRole));
    }

    return selectedRoles.includes(theRole);
  }

  return (
    <OnboardingWizardItem title="Professional Role" 
    description="Provide information about your role in your profession." handleSubmit={handleSubmit(onSubmit)} {...props.nav}>
      <div className="pt-2 px-4" style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '85%', margin: '0 auto'}}>

        {roleOptions.map((theRole) =>
          <Form.Field key={`role_${theRole.id}`} style={{width: '33%'}}>
                <Form.Checkbox checked={selectedRoles.includes(theRole.id)} onChange={e => toggleRole(theRole.id, e.target.checked)}>{theRole.description}</Form.Checkbox>
          </Form.Field>)
        }  

        <Form.Field horizontal className="py-3">
          <Form.Field.Label className="is-normal" style={{ flexGrow: 0 }}>
            <Form.Label className="label">Other:</Form.Label>
          </Form.Field.Label>
          <Form.Field.Body>
            <Form.Field>
              <Form.Control>
                <Form.Input value={otherRole} onChange={e => setOtherRole(e.target.value)} ></Form.Input>
              </Form.Control>
            </Form.Field>
          </Form.Field.Body>
        </Form.Field>

      </div>

    </OnboardingWizardItem>

  )
}

export default CreateProfileRole;