import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bulma-components';
import Likert from 'react-likert-scale';
import SurveyStars from '../SurveyStars/SurveyStars';

function AnswerComponent({ questionId, alreadyCompleted, question, updateAnswer, answer, horizontal }) {
  const [inputError, setInputError] = useState("");
  const [prevInputVal, setPrevInputVal] = useState();

  const refLikert = useRef(null);

  function setAnswer(questionId, val) {
    //if (val instanceof Array) val = JSON.stringify(val);
    updateAnswer(questionId, val);
  }

  function ratingComponent() {
    return <div className="is-flex is-justify-content-space-between is-align-items-center">
      <SurveyStars disabled={alreadyCompleted} onChange={(val) => setAnswer(questionId, val)} value={answer} />
    </div>
  }

  function toggleOptionInArray(option) {
    let theAnswer = answer;

    if (theAnswer instanceof Array) {
      if (theAnswer.includes(option)) {
        setAnswer(questionId, [...theAnswer.filter(f => f !== option)]);
      }
      else {
        setAnswer(questionId, [...theAnswer, option]);
      }
    }
    else {
      setAnswer(questionId, [option]);
    }
  }

  useEffect(() => {
    if (question.answerType.startsWith('likert') && answer !== null) {
      const theAnswer = (answer === null ? 3 : parseInt(answer));

      const option = refLikert.current.querySelectorAll(
        'input[type="radio"]'
      )[theAnswer-1];
      
      if (option) option.checked = true;
    }
  })

  function likertScaleComponent() {

    const likertOptions = {
      id: `${question.answerType}_${questionId}`,
      responses: [
        { value: 1, text: "Strongly disagree" },
        { value: 2, text: "Disagree" },
        { value: 3, text: "Neither" },
        { value: 4, text: "Agree" },
        { value: 5, text: "Strongly Agree"}
      ],
      onChange: val => { setAnswer(questionId, val.value.toString()) }
    };

    if (question.answerTypeParams) {
      likertOptions.responses = question.answerTypeParams.map((text, idx) => { return { value: idx+1, text: text }});
    }
    
    return (
      <Likert className="mt-3" {...likertOptions} ref={refLikert} />
    )
  }

  function multiSelect() {
    let optionsArray = [];
    try {
      if (question.predefinedValues !== '{}') {
        optionsArray = (question.predefinedValues instanceof Array) ? question.predefinedValues : JSON.parse(question.predefinedValues);
      }
    }
    catch {
      let optionsToArray = question.predefinedValues.substring(1, question.predefinedValues.length - 1);
      optionsArray = optionsToArray.split(",");
    }

    if (optionsArray.length === 0) {
      console.log('prob', question);
    }
    
    const answerArray = (answer instanceof Array) ? answer : (answer ? JSON.parse(answer) : []);
    return (
      <div className="py-2" style={horizontal ? {} : {display: 'flex', flexWrap: 'wrap'}}>

      { optionsArray.map((option, idx) => 
        <Form.Field key={`option_${idx}`} style={horizontal ? {width: '100%'} : {width: '33%'}}>
          <Form.Checkbox checked={answerArray.includes(option)} onChange={e => toggleOptionInArray(option, e.target.checked)}>{option}</Form.Checkbox>
        </Form.Field>)}
      </div>
    )
  }

  function selectComponent() {
    let optionsArray = [];
    try {
      optionsArray = JSON.parse(question.predefinedValues);
    }
    catch {
      let optionsToArray = question.predefinedValues.substring(1, question.predefinedValues.length - 1);
      optionsArray = optionsToArray.split(",");
    }

    return <Form.Field className="mt-2 mb-4">
      <Form.Select value={answer || ''} onChange={e => setAnswer(questionId, e.target.value)} >
        <option key={`option_none`} value={null}>Select...</option>
        {optionsArray.map((val, idx) =>
          <option key={`option_${idx}`} value={val}>{val}</option>
        )}
      </Form.Select>
    </Form.Field>
  }

  function boolComponent() {
    return <Form.Field className="mt-2 mb-4">
      <Form.Select value={answer} onChange={e => setAnswer(questionId, e.target.value)} >
        <option value={null}>N/A</option>
        <option value="1">Yes</option>
        <option value="0">No</option>
      </Form.Select>
    </Form.Field>
  }

  function forceValidNumberValue(questionId, newValue, oldValue) {
    const max = (question.max === undefined || question.max === null || question.max === 0) ? Number.MAX_VALUE : parseInt(question.max);
    const min = (!question.min) ? 0 : parseInt(question.min);
    const val = parseInt(newValue); 
    if (isNaN(val) || val < min || val > max) setInputError(question.max === Number.MAX_VALUE ? `This answer must be at least ${min}` : `This answer must be between ${min} and ${max}`);
  }

  function numberComponent() {
    const currentAnswer = (answer && !isNaN(answer)) ? parseInt(answer) : parseInt(question.min);
    const max = (question.max === undefined || question.max === null || question.max === 0) ? Number.MAX_VALUE : parseInt(question.max);
    const min = (!question.min) ? 0 : parseInt(question.min);

    return <Form.Field className="mt-2 mb-4">
      <Form.Input style={{width: 130}} type="number" min={min} max={max} value={currentAnswer} onChange={e => setAnswer(questionId, e.target.value)}
        onBlur={(e) => forceValidNumberValue(questionId, e.target.value, prevInputVal)}
        />
      {inputError && <Form.Help color="danger">{inputError}</Form.Help>}
    </Form.Field>
  }

  function textComponent() {
    return <Form.Field className="mt-2 mb-4">
      <Form.Control>
        {alreadyCompleted ?
          (answer ?
            <div>{answer}</div>
            :
            <span className="explanationText has-text-grey-light">Not provided</span>
          )
          :
          <textarea rows={horizontal ? 1 : 2} onChange={(e) => setAnswer(questionId, e.target.value)} className="textarea mb-2" value={answer || ""} />
        }
      </Form.Control>
    </Form.Field>
  }

  return <>
    {question.answerType === 'rating' && ratingComponent()}
    {question.answerType === 'integer' && numberComponent()}
    {question.answerType === 'boolean' && boolComponent()}
    {question.answerType === 'free text' && textComponent()}
    {question.answerType.startsWith('likert') && likertScaleComponent()}
    {question.answerType === 'predefined values' && selectComponent()}
    {question.answerType === 'multiselect' && multiSelect()}
  </>
}

export default AnswerComponent;