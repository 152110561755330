import OnboardingScreen from '../../screens/OnboardingScreen/OnboardingScreen.js';
import CreateProfileGetStarted from '../../components/CreateProfileGetStarted/CreateProfileGetStarted';
import CreateProfileDetails from '../../components/CreateProfileDetails/CreateProfileDetails';
// import CreateProfileEducationInfo from '../../components/CreateProfileEducationInfo/CreateProfileEducationInfo';
// import CreateProfilePersonal from '../../components/CreateProfilePersonal/CreateProfilePersonal';
// import CreateProfileComplete from '../../components/CreateProfileComplete/CreateProfileComplete';

function MentorOnboarding() {
  const Pages = [
    [CreateProfileGetStarted, null],
    [CreateProfileDetails, null],
    // [CreateProfileEducationInfo, null],
    // [CreateProfilePersonal, null],
    // [CreateProfileComplete, null],
  ]

  const navProps = {
    nav: {
      finishText: "Next",
      finishLinkTo: "/menteeprofileinfo",
    }
  }

  return (
    <OnboardingScreen Pages={Pages} isMentor={false} {...navProps} />
  );
}

export default MentorOnboarding;