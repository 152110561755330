import { useState } from 'react';
import { useStore } from '../../../store/Store';
import { Form, Heading } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { emptySchemaUI } from '../../../schemas';
import { patchUser } from '../../../lib/api';
import { alphaSort99 } from '../../../lib/formHelpers';

import OnboardingWizardItem from '../../OnboardingWizardItem/OnboardingWizardItem';


function InfoSheetPersonalInterests(props) {
  const { state, dispatch } = useStore();
  const [ selectedInterests, setSelectedInterests ] = useState(state.user?.infoSheet?.personalInterests ? [...state.user.infoSheet.personalInterests.map((m) => m.id)] : []);
  const [otherPersonalInterest,setPersonalInterest] = useState(state.user?.infoSheet?.otherPersonalInterest || '');

  
  const personalInterests = [...state.staticValues.personalInterestChoices.sort(alphaSort99)];

  function hasInterest(interest) {
    return selectedInterests.includes(interest);
  }

  function toggleInterest(interest, hasInterest) {
    if (hasInterest) {
      setSelectedInterests([...selectedInterests, interest]);
    }
    else {
      setSelectedInterests(selectedInterests.filter(e => e !== interest));
    }
    return selectedInterests.includes(interest);
  }

  const formParams = {
    resolver: joiResolver(emptySchemaUI),
  }

  const { handleSubmit } = useForm(formParams);

  async function onSubmit(/*data*/) {

    try {
      const updatedData = {
        personalInterests: [...selectedInterests],
        otherPersonalInterest : otherPersonalInterest,
      }

      await patchUser(props.isMentor, state.user, updatedData, dispatch);
      
      props.nav.onNext();
    }
    catch (err) {
      console.error('Personal Interest err', err);
    }
  }

  return (
    <OnboardingWizardItem title="Personal Interests" description="Tell us about your interests. These commonalities between Mentor and Mentee provide a deeper connection of shared interests and enjoyments." handleSubmit={handleSubmit(onSubmit)} {...props.nav}>

      {/* TODO */}
      <Heading subtitle>Select all that apply</Heading>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          { personalInterests.map((interest) => 
            <Form.Field key={`PersonalInterest_${interest.id}`} style={{width: '33%'}}>
              <Form.Checkbox checked={hasInterest(interest.id)} onChange={e => toggleInterest(interest.id, e.target.checked)}>{interest.description.replaceAll('/', ' / ')}</Form.Checkbox>
          </Form.Field>)
          }



        </div>

            {(hasInterest(19) === true) ? 

          <Form.Field horizontal className="py-3">
          <Form.Field.Label className="is-normal" style={{ flexGrow: 0 }}>
            <Form.Label className="label">Other:</Form.Label>
              </Form.Field.Label>
              <Form.Field.Body>
              <Form.Field>
              <Form.Control>
                <Form.Input value= {otherPersonalInterest} maxLength = "100" onChange={e => setPersonalInterest(e.target.value)} className="is-normal" placeholder = "Enter an additional interest."></Form.Input>
                </Form.Control>
              </Form.Field>
            </Form.Field.Body>
          </Form.Field>
              : null}

    </OnboardingWizardItem>


  )
}

export default InfoSheetPersonalInterests;