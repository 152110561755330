import { Modal } from 'react-bulma-components';
import ProfilePictureUploader from "../ProfilePictureUploader/ProfilePictureUploader";

function ProfilePictureUploaderModal(props) {
  return (
    <Modal showClose={false} show={props.show} onClose={props.onClose}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">Set Your Profile Picture</p>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <ProfilePictureUploader onSaved={props.onSaved} onCancel={props.onCancel} />
        </Modal.Card.Body>
        {/* <Modal.Card.Footer className="modal-card-foot is-flex is-justify-content-flex-end">
          <button className="button" onClick={props.onCancel}>Cancel</button>
        </Modal.Card.Footer> */}
      </Modal.Card>
    </Modal>
  );
};

export default ProfilePictureUploaderModal;
