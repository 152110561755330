import { useState } from 'react';
import { useStore } from '../../store/Store';
import { Modal, Block, Form, Content } from 'react-bulma-components';
import { format, parseISO } from 'date-fns';
import { patchUser } from '../../lib/api';

function RulesOfEngagmentModal(props) {
  const { state, dispatch } = useStore();
  const [accepted, setAccepted] = useState(false);

  function onClose() {
    
    if (!props.user.roeAcceptedOn) {
      try {
        const updatedData = {
          roeAcceptedOn: new Date().toISOString(),
        }

        patchUser(props.isMentor, state.user, updatedData, dispatch);
      }
      catch (err) {
        console.log('Accept roe err', err);
      }
    }

    if (props.onClose) {
      props.onClose();
    }

  }

  return (
    <Modal showClose={false} show={props.open} onClose={() => props.onClose()}>
      <Modal.Card>
        <Modal.Card.Header showClose={false}>
          <p className="modal-card-title">Engagement Etiquette</p>
          {/* <button class="delete" aria-label="close" onClick={() => props.onClose()}></button> */}
        </Modal.Card.Header>
        <Modal.Card.Body>
          <Content>

            <ol>
              <li>I will ensure that the mentorship sessions take place within the allotted membership timeframe.</li>
              <li>I will be respectful of others' time. (Show up on time &amp; end on agreed-upon time)</li>
              <li>I will exhibit professionalism (appearance, background/location - noise) and be present during each mentoring session.</li>
              <li>I will respect others opinions (this does not constitute agreement).</li>
              <li>I will be authentically curious and prepared to ask questions.</li>
            </ol>
          </Content>

          {props.user.roeAcceptedOn ?
            <Block className="has-text-centered" style={{ fontWeight: 'bold' }}>
              You accepted the Engagement Etiquette on {format(parseISO(props.user.roeAcceptedOn), 'PP')}
            </Block>
            :

            <Form.Field className="has-text-centered" style={{ fontWeight: 'bold' }}>
              <Form.Checkbox checked={accepted} onChange={e => setAccepted(e.target.checked)}>I agree to the Engagement Etiquette</Form.Checkbox>
            </Form.Field>
          }
        </Modal.Card.Body>
        <Modal.Card.Footer className="modal-card-foot is-flex is-justify-content-flex-end">
          <button className="button is-success" disabled={!props.user.roeAcceptedOn && !accepted} onClick={() => onClose()}>OK</button>
        </Modal.Card.Footer>

      </Modal.Card>
    </Modal>
  )
};

export default RulesOfEngagmentModal;