import { useState } from 'react';
import { useStore } from '../../store/Store';
import { Columns, Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { emptySchemaUI } from '../../schemas';
import { patchUser } from '../../lib/api';

import OnboardingWizardItem from '../OnboardingWizardItem/OnboardingWizardItem';

function CreateProfileSocial(props) {

  const { state, dispatch } = useStore();

  const [linkedIn, setLinkedin ] = useState(state.user?.linkedinProfile || '');
  const [instagram, setInstagram ] = useState(state.user?.instagram || '');
  const [workWebsite, setWorkWebsite ] = useState(state.user?.workWebsite || '');
  const [personalWebsite, setPersonalWebsite ] = useState(state.user?.personalWebsite || '');
  const [twitter, setTwitter ] = useState(state.user?.twitter || '');


  const formParams = {
    resolver: joiResolver(emptySchemaUI),
    defaultValues: {

    }
  }

  const { /*register,*/ handleSubmit } = useForm(formParams);

  async function onSubmit(data) {
    try {
      const updatedData = {
        linkedinProfile: linkedIn, 
        workWebsite: workWebsite, 
        personalWebsite: personalWebsite, 
        instagram: instagram, 
        twitter: twitter,
      }
      await patchUser(props.isMentor, state.user, updatedData, dispatch);
      props.nav.onNext();
    }
    catch (err) {
      console.log('Social Page err', err);
    }
  }



  return (
    <OnboardingWizardItem title="Social" description="Please provide the following social profiles. This will allow your match to connect with you on different media." handleSubmit={handleSubmit(onSubmit)} {...props.nav}>

    <Columns>
      <Columns.Column className= "is-one-half">
        <Form.Field>
            <Form.Label > LinkedIn </Form.Label> 
            <Form.Input value={linkedIn} onChange={e => setLinkedin(e.target.value)} className="is-normal" placeholder="" />
          </Form.Field>
          <Form.Field>
            <Form.Label > Instagram </Form.Label> 
            <Form.Input value={instagram} onChange={e => setInstagram(e.target.value)} className="is-normal" placeholder="" />
          </Form.Field>

          <Form.Field>
            <Form.Label > Twitter </Form.Label>
            <Form.Input value={twitter} onChange={e => setTwitter(e.target.value)} className="is-normal" placeholder="" />
          </Form.Field>

      </Columns.Column>

      <Columns.Column className= "is-one-half">

        <Form.Field>
          <Form.Label > Work Website </Form.Label>
          <Form.Input value={workWebsite} onChange={e => setWorkWebsite(e.target.value)} className="is-normal" placeholder="" />
        </Form.Field>

        <Form.Field>
          <Form.Label > Personal Website </Form.Label>
          <Form.Input value={personalWebsite} onChange={e => setPersonalWebsite(e.target.value)} className="is-normal" placeholder="" />
        </Form.Field>
      </Columns.Column>

      </Columns>
    </OnboardingWizardItem>


  )

}

export default CreateProfileSocial;