import { useLocation } from 'react-router-dom';
import OnboardingScreen from '../OnboardingScreen/OnboardingScreen.js';
//import InfoSheetHobbies from '../../components/InfoSheet/InfoSheetHobbies/InfoSheetHobbies.js';
import MentorGoals from '../../components/MentorGoals/MentorGoals.js';
import InfoSheetPersonality from '../../components/InfoSheet/InfoSheetPersonality/InfoSheetPersonality.js';
import InfoSheetPersonalInterests from '../../components/InfoSheet/InfoSheetPersonalInterests/InfoSheetPersonalInterests';
import InfoSheetBusinessInterests from '../../components/InfoSheet/InfoSheetBusinessInterests/InfoSheetBusinessInterests';
import InfoSheetRecommendations from '../../components/InfoSheet/InfoSheetRecommendations/InfoSheetRecommendations';
import InfoSheetComplete from '../../components/InfoSheet/InfoSheetComplete/InfoSheetComplete';
import InfoSheetLocation from '../../components/InfoSheet/InfoSheetLocation/InfoSheetLocation';


function MentorInfoSheetWizard() {
  const redirectLink = new URLSearchParams(useLocation().search).get('r') || '';

  let Pages = [
    [InfoSheetPersonality, null],
    [InfoSheetPersonalInterests, null],
    [InfoSheetBusinessInterests, null],
    [InfoSheetLocation, null],
    [MentorGoals, null],
    [InfoSheetRecommendations, null], // Keep this one last - it has a selective redirect
  ]

  if (!redirectLink) {
    Pages.push([InfoSheetComplete, null])
  }
  
  return (
    <OnboardingScreen Pages={Pages} isMentor={false} />
  );
}

export default MentorInfoSheetWizard;
