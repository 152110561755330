import { Box, Heading, Container, Section } from 'react-bulma-components';
import { arrayToCsvCapitalized, notProvidedString, locationString } from '../../lib/stringHelpers.js';
import { alphaSort99 } from '../../lib/formHelpers';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture.js';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import MatchedProfile from '../../components/MatchedProfile/MatchedProfile';

function MenteeMatchProfile(props) {

  const TernProfile = () => {
    return (<div className="container is-max-widescreen">
      <Heading align="center" >
        {props.profile.firstName} {props.profile.lastName}
      </Heading>

      <Heading align="center" subtitle>
          {props.profile.education.university}<br />
          {arrayToCsvCapitalized(props.profile.education.majorDegrees)}
          {props.profile.education.minorDegrees != null ? ", " + props.profile.education.minorDegrees : ''}
      </Heading>

      <Box className="is-mobile-size-7 is-size-5">

        <div className="profile-info-wrapper">

          <div className="mb-3" style={{height: 350, width: 350, textAlign: 'center', margin: '0 auto'}}>
              <ProfilePicture picture={props.profile.profilePic} />
            </div>
            <ContactInfo profile={props.profile} />

        </div>

        <Section className="pt-4 pb-3 pl-3">
          <strong>Preferred Pronouns:</strong> {notProvidedString(props.profile.preferredPronouns)}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Location:</strong> {notProvidedString(locationString(props.profile.infoSheet?.currentCity, props.profile.infoSheet?.currentState, props.profile.infoSheet?.currentCountry))}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Hometown: </strong> {notProvidedString(locationString(props.profile.infoSheet?.homeTown, props.profile.infoSheet?.hometownState, props.profile.infoSheet?.hometownCountry))}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Graduation Year: </strong> {props.profile.education?.graduationDate && props.profile.education.graduationDate.slice(0, 4)}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Advanced Degree(s): </strong> {notProvidedString(arrayToCsvCapitalized(props.profile.education?.advancedDegrees))}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Language(s): </strong> {notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.languages.map(m => m.description).sort(alphaSort99)))}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Personality Test(s): </strong> {notProvidedString(props.profile.infoSheet.personalityTestText)}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Interests: </strong> {notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.personalInterests.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherPersonalInterest))}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Business Interests: </strong>  {notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.businessInterests.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherBusinessInterest))}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Affinity Groups: </strong> {notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.affinityGroups.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherAffinityGroup))}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Goals Statement: </strong> {notProvidedString(props.profile.infoSheet?.goalsStatement)}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Formative Experience: </strong> {notProvidedString(props.profile.infoSheet?.formativeExperience)}
        </Section>

        <Section className="pl-3 py-4" >
          <strong>Career Aspiration: </strong> {notProvidedString(props.profile.infoSheet?.careerInspiration)}
        </Section>

        <Section className="pl-3 py-4" >
          <strong> Strengths: </strong> {notProvidedString(props.profile.infoSheet?.strength)}
        </Section>

        <Section className="pl-3 py-4" >
          <strong> Would Like To: </strong> {notProvidedString(props.profile.infoSheet?.toImprove)}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Additional Info: </strong> {notProvidedString(props.profile.infoSheet?.additionalInfo)}
        </Section>

        <Section className="pl-3 py-4">
          <strong>Recommendations: </strong> {(props.profile.infoSheet.recommendations && props.profile.infoSheet.recommendations.length > 0) ? props.profile.infoSheet.recommendations.map(m => <>{m}<br /></>) : 'None provided'}
        </Section>
      </Box>      
    </div>);
  }

  return (
    <Container alignContent="center" className="container is-fullwidth" style={{ paddingTop: '3.25rem' }}>
      { props.profile.groupId === 1 ? <TernProfile /> : <MatchedProfile {...props} /> }
    </Container>
  )


}

export default MenteeMatchProfile;