import { Heading, Container } from 'react-bulma-components';
import { useAuthenticator } from '@aws-amplify/ui-react';

import UserTypeSelectors from '../../components/UserTypeSelectors/UserTypeSelectors';

function Signup() {
  const { user: cognitoUser } = useAuthenticator((context) => [context.route]);
  const isTern = cognitoUser.attributes['custom:signupcode'] === '201905';

  return (
    <div>
      <div className="login-background" />
      { isTern !== null && 
      <Container style={{paddingTop: 100}} className="has-text-centered">
        <Heading style={{ fontWeight: 'bold' }}>Welcome!</Heading>
        <Heading style={{ fontWeight: 'bold' }} subtitle>We're glad to have you here!<br />Please select how you would like to participate.</Heading>
        <UserTypeSelectors isTern={isTern} />
      </Container>
      }
    </div>
  )
}

export default Signup;
