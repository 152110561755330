import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ExternalLink(props) {

  return (
    <a href={props.href} rel="noreferrer" target="_blank">
      {props.children}
      &nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} />
    </a>
  )
}

export default ExternalLink;