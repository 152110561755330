import './Dashboard.scss';
import config from '../../config';
import { useEffect, useState } from 'react';
import { useStore } from '../../store/Store';
import { getUser, /*getUserQuestionsAndAnswers*/ } from '../../lib/api';
import { useHistory } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Heading, Columns, Block, Card, Box } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import ProfilePictureUploaderModal from '../../components/ProfilePictureUploadModal/ProfilePictureUploadModal';
import StatusItemBox from '../../components/StatusItemBox/StatusItemBox';
import MentorMenteeBox from '../../components/MentorMenteeBox/MentorMenteeBox';
import UpcomingEvents from './Sections/UpcomingEvents/UpcomingEvents';
import Resources from './Sections/Resources/Resources';
import Roadmap from './Sections/Roadmap/Roadmap';
// import ProgressSteps from '../../components/ProgressSteps/ProgressSteps';
// import MenteePaymentModal from '../../components/MenteePaymentModal/MenteePaymentModal';
import MentorshipCompleteModal from '../../components/MentorshipCompleteModal/MentorshipCompleteModal';

import MentorshipFeedbackModal from '../../components/MentorshipFeedback/MentorshipFeedbackModal/MentorshipFeedbackModal';
import BannerPicture from '../../components/BannerPicture/BannerPicture';
import TodoItem from '../../components/TodoItem/TodoItem';
import { patchUser, getMatched } from '../../lib/api';

import { isAfter, parseISO, addHours } from 'date-fns';

function Dashboard() {
  const history = useHistory();
  const [ loading, setLoading] = useState(true);
  const { state, dispatch } = useStore();
  const { user: cognitoUser } = useAuthenticator((context) => [context.user]);
  const [ showProfileDialog, setShowProfileDialog ] = useState(false);
  const [ showMenteePaymentDialog, setShowMenteePaymentDialog ] = useState(false);
  const [ showMentorshipCompletedDialog, setShowMentorshipCompletedDialog ] = useState(false);
  const [ showMentorshipFeedbackDialog, setShowMentorshipFeedbackDialog ] = useState(false);
  const [ mentorshipCompletedOn, setMentorshipCompletedOn ] = useState(state.matched?.mentorship?.completion_date);

  const [ offboardingAvailable, setOffboardingAvailable ] = useState(false);

  // const unscheduledSessionsCount = state.matched?.mentorship?.sessions && state.matched.mentorship.sessions.length - state.matched.mentorship.sessions.filter(s => s.session_date).length;
  const uncompletedMenteeSessionsCount = state.matched?.mentorship?.sessions && state.matched.mentorship.sessions.length - state.matched.mentorship.sessions.filter(s => s.mentee_survey).length;
  const uncompletedMentorSessionsCount = state.matched?.mentorship?.sessions && state.matched.mentorship.sessions.length - state.matched.mentorship.sessions.filter(s => s.mentor_survey).length;

  let deadline = new Date(2099, 12, 31);
  try {
    deadline = parseISO(state.user?.group?.offboardingDate);
  }
  catch { }

  function onCloseProfileDialog() {
    setShowProfileDialog(false);
  }

  async function onProfileSaved() {
    try {
      let userData = await getUser(cognitoUser.username);
      dispatch( { type: 'SET_USER', payload: userData });
    }
    catch (err) {
      console.error('Error refreshing profile');
    }
    finally {
      setShowProfileDialog(false);
    }
  }

  useEffect(() => {
    if (state.mentorshipHasBeenCompleted) {
      setShowMentorshipCompletedDialog(true);
      dispatch({type:'RESET_MENTORSHIP_COMPLETED'});
    }
  }, [state.mentorshipHasBeenCompleted, dispatch])

  useEffect(() => {
    setMentorshipCompletedOn(state.matched?.mentorship?.completion_date);
  }, [state.matched?.mentorship?.completion_date]);

  useEffect(() => {
    if (!state.user) {
      console.log('User not setup yet - redirecting');
      history.push('/createaccount');
      setLoading(false);
    }
    else {
      setLoading(false);

      if (state.user?.activeProfile?.contactType?.id === 1 && state.matched?.matched_with && !state.matched?.paid) {
        setShowMenteePaymentDialog(true);
      }
    }
  }, [state, history])

  useEffect(() => {
    let isPastLastSession = false;
    if (state.matched?.mentorship?.sessions) {
      const theSessions = [...state.matched?.mentorship?.sessions];
      if (theSessions && theSessions.length > 0) {
        theSessions.sort((a,b) => parseInt(a.session_num) - parseInt(b.session_num));
        console.log('theSessions', theSessions);

        const lastSession = theSessions[theSessions.length-1].session_date;
        isPastLastSession = isAfter(new Date(), addHours(parseISO(lastSession), 2));

        console.log('isPastLastSession', isPastLastSession);

        setOffboardingAvailable(isPastLastSession);
      }
    }
  }, [state]);

  // For convenience
  const isMentee = state.user?.activeProfile?.contactType?.id === 1;
  const isMentor = state.user?.activeProfile?.contactType?.id === 2;
  const isTERN = state.user?.groupId === 1;
  const inMentorship = state.matched?.mentorship;
  
  const [availability, setAvailability] = useState(state.user?.isAvailableForMatch);

  const accountIsActive = true;//state.user.accountStatus === 'active';
  const profileComplete = state.user?.wizard1Page === 99;
  const ennegramComplete = true;

  // const ennegramComplete = state.user?.infoSheet?.enneagram?.id;
  const infoSheetComplete = state.user?.wizard2Page === 99;
  const roadmapComplete = profileComplete && (state.user?.matchType === 'random' || 
    (ennegramComplete && infoSheetComplete)) && state.user?.profilePic;

  async function onMenteePayment() {
    let matchedWithData = await getMatched();
    dispatch( { type: 'SET_MATCHED_WITH', payload: matchedWithData });
    setShowMenteePaymentDialog(false);
  }

  function getCanMatch() {
    if (isMentee) {
        if (state.user.matchType === 'random') {
        return profileComplete && accountIsActive;
      }
      else {
        return profileComplete && infoSheetComplete && ennegramComplete && accountIsActive;
      }
    }
    else {
      return profileComplete && infoSheetComplete && ennegramComplete && accountIsActive;
    }
  }

  // function onChangeMatchType() {
  //   history.push('/matchchoice');
  // }

  function changeAvailability(newAvailability)
  {
    try {
      const updatedData = {
        isAvailableForMatch : newAvailability,
      }

      patchUser(isMentor, state.user, updatedData, dispatch);
      setAvailability(newAvailability);
    }
    catch (err) {
      console.log('Error updating availability', err);
    }
  }

  function startOffboarding() {
    history.push('/offboarding');
  }

  // const OffboardingSection = () => {
  //   if (!mentorshipCompletedOn && state.matched?.mentorship && (isAfter(new Date(), deadline) || offboardingAvailable)) {
  //     return (
  //       <Box>
  //         <TodoItem title="Complete the offboarding survey" description="Complete the final survey about your experience." unlocked={true} completed={state.user.offboardingCompletedOn} onClick={startOffboarding} />
  //       </Box>);
  //   }

  //   return null;
  // }

  const companyLogo = state.user.group.logoFilename && `${config.s3Root}${state.user.group.logoFilename}`;

  return ( <div>
    { !loading && state.user ?
    <div className="container is-widescreen dashboard">
      <Block>
        <Card>
          <Card.Content className="is-flex is-flex-direction-column" style={{padding: 0}}>
            <BannerPicture picture={state.user.group.bannerPic} color1={state.user.group.color1} color2={state.user.group.color2} />
          </Card.Content>

          <div className="py-2 px-2 is-flex" style={{alignItems: 'center'}}>
            { companyLogo && 
              <div>
                <img src={companyLogo} style={{height: 62, width: 62, marginRight: 10}} alt="" />
              </div>
            }
            <div>
              <Heading>{state.user.group.companyName}</Heading>
              <Heading subtitle>
                <a href={state.user.group.companyWebsite} target="_blank" rel='noreferrer'>
                  <div style={{verticalAlign: 'center'}}>
                  <span>Website</span>
                  <span style={{fontSize: 'smaller', verticalAlign: 'middle', paddingBottom: 8, paddingLeft: 4}}> <FontAwesomeIcon icon={faExternalLinkAlt} /></span>
                  </div>
                </a>
              </Heading>
            </div>
          </div>
        </Card>
      </Block>

      <Block size="small">

        <Columns>
          <Columns.Column size="two-thirds">
            {/* <OffboardingSection /> */}
            { roadmapComplete ? <>

              { (offboardingAvailable || (mentorshipCompletedOn && ((isMentor && uncompletedMentorSessionsCount === 0) || (!isMentor && uncompletedMenteeSessionsCount === 0) || isAfter(new Date(), deadline)))) ? 
                <Block>
                  <Roadmap mentorshipCompletedOn={mentorshipCompletedOn} offboardingCompletedOn={state.user.offboardingCompletedOn} offboardingAvailable={offboardingAvailable} hasOnboardingQuestions={state.user.group.onboardingQuestionCount > 0 || isTERN} onStartOffboarding={startOffboarding} onUploadProfile={() => setShowProfileDialog(true)} onShowFeedback={() => setShowMentorshipFeedbackDialog(true)} feedbackComplete={(isMentor && state.matched?.mentorship?.mentor_feedback) || (isMentee && state.matched?.mentorship?.mentee_feedback)} user={state.user} hasSubscription={state.user.subscription?.active} profileComplete={profileComplete} ennegramComplete={ennegramComplete} infoSheetComplete={infoSheetComplete} />
                </Block>
              :
                <Block>
                  <Roadmap mentorshipCompletedOn={mentorshipCompletedOn} offboardingCompletedOn={state.user.offboardingCompletedOn} offboardingAvailable={offboardingAvailable} user={state.user} noItems={true} />
                </Block>
              }

              <Block>
                <UpcomingEvents offboardingCompletedOn={state.user.offboardingCompletedOn}  isMentor={isMentor} user={state.user} mentorshipCompletedOn={mentorshipCompletedOn} onStartOffboarding={startOffboarding} />
              </Block>

            </>
            :
            <>
              <Block>
                <Roadmap mentorshipCompletedOn={mentorshipCompletedOn} offboardingCompletedOn={state.user.offboardingCompletedOn} offboardingAvailable={offboardingAvailable} hasOnboardingQuestions={state.user.group.onboardingQuestionCount > 0 || isTERN} onStartOffboarding={startOffboarding} onUploadProfile={() => setShowProfileDialog(true)} user={state.user} hasSubscription={state.user.subscription?.active} profileComplete={profileComplete} ennegramComplete={ennegramComplete} infoSheetComplete={infoSheetComplete} />
              </Block>

              <Block>
                <UpcomingEvents offboardingCompletedOn={state.user.offboardingCompletedOn}  user={state.user} isMentor={isMentor} onStartOffboarding={startOffboarding} />
              </Block>
            </>
            }

            <Block>
              <Resources user={state.user} isMentor={isMentor} groupId={state.user.groupId} EnnegramNumber={state.user.infoSheet.enneagram.id} />
            </Block>

            { isTERN && isMentor ? 
              <StatusItemBox style={{padding: 4, paddingLeft: 24}} buttonColor="success" buttonText="DONATE" icon={faHandHoldingUsd} title="Fan of mentorship?" onClick={() => history.push('/makedonation')}>
                Donate Here!
              </StatusItemBox>
              : null 
            }
  
          </Columns.Column>

          <Columns.Column>
            { isMentor && ((roadmapComplete && (!state.matched?.matched_with || state.matched?.mentorship?.completion_date)) || isAfter(new Date(), deadline)) && 
              <Box className="p-5 has-text-centered">
                <div className="field">
                  <input id="availableForMatchingSwitch" type="checkbox" name="switchExample" className="switch" defaultChecked= {availability === true ? "checked" : ""} onChange = { e => changeAvailability(e.target.checked)} />
                  <label htmlFor="availableForMatchingSwitch">I Am Available for Matching</label>
                </div>
              </Box>
            }

            <MentorMenteeBox isMentor={isMentor} matchType={state.user.matchType} 
              needPayment={!inMentorship && state.matched?.paid === false} canMatch={getCanMatch()} isMatching={getCanMatch()}
              profile={inMentorship ? state.matched?.matched_with : null} 
              onMakePayment={() => setShowMenteePaymentDialog(true)}
              />
          </Columns.Column>
        </Columns>

      </Block>
    </div>
    : null}

    <ProfilePictureUploaderModal show={showProfileDialog} onClose={onCloseProfileDialog} onCancel={onCloseProfileDialog} onSaved={onProfileSaved} />

    {/* { showMenteePaymentDialog && 
      <MenteePaymentModal onPayment={onMenteePayment} show={showMenteePaymentDialog} onClose={() => setShowMenteePaymentDialog(false) } onCancel={() => setShowMenteePaymentDialog(false) } /> 
    } */}

    { showMentorshipCompletedDialog &&
      <MentorshipCompleteModal show={showMentorshipCompletedDialog} onClose={()=> setShowMentorshipCompletedDialog(false)} />
    }

    <MentorshipFeedbackModal open={showMentorshipFeedbackDialog} isMentor={isMentor} mentorship={state?.matched?.mentorship} onClose={() => setShowMentorshipFeedbackDialog(false)} onCancel={() => setShowMentorshipFeedbackDialog(false)} />
  </div>
  )
}

export default Dashboard;
