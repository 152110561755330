import { Heading, Box, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function StatusItemBox(props) {

  return (
  <Box>
    <div className="is-flex is-align-items-center" style={props.style}>
      <div>
        { props.icon ? <FontAwesomeIcon className="mr-4 has-text-info" size="3x" icon={props.icon} /> : null }
      </div>
      <div className="pl-4 is-flex-grow-1">
        <Heading>
          {props.title}
        </Heading>
        {props.children}
      </div>
      <div className="px-4">
        <Button color={props.buttonColor} onClick={props.onClick}>{props.buttonText}</Button>
      </div>
    </div>
  </Box>
  )
}

export default StatusItemBox;