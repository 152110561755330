import { useState } from 'react';
import { useStore } from '../../../../store/Store';
import { Heading, Card, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faPoll, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO, startOfDay, isBefore, isAfter, isEqual } from 'date-fns';
import { differenceInWeeks } from 'date-fns/esm';

import RulesOfEngagmentModal from '../../../../components/RulesOfEngagmentModal/RulesOfEngagmentModal';
import SessionModal from '../../../../components/Sessions/SessionModal/SessionModal';
import SessionNotesModal from '../../../../components/Sessions/SessionNotesModal/SessionNotesModal';

function UpcomingEvents(props) {
  const { state } = useStore();
  const [ showRulesOfEngagementModal, setShowRulesOfEngagementModal ] = useState(false);
  const [ showSessionModal, setShowSessionModal ] = useState(null);
  const [ showSessionNotesModal, setShowSessionNotesModal ] = useState(null);

  const sessions = state.matched?.mentorship?.sessions;
  const mentorshipDuration = state.user.group.mentorshipDuration;

  function showSessionOrOffboardingModal(sessionNum, forSurvey, isCompleting) {
    console.log('mentorshipDuration', mentorshipDuration, sessionNum, sessions.length);
    if (sessionNum === sessions.length-1) {
      props.onStartOffboarding();
    }
    else {
      setShowSessionModal({ session: sessionNum, forSurvey, isCompleting});
    }
  }

  function getButtons(session) {
    const buttons = [];

    const sessionNumToShow = parseInt(session.session_num)-1;
    let surveyCompleted = session.completed && ((props.isMentor && session.mentor_survey !== null) || (!props.isMentor && session.mentee_survey !== null));
    if (sessionNumToShow === sessions.length-1 && props.offboardingCompletedOn) {
      surveyCompleted = true;
    }

    if (surveyCompleted || isAfter(new Date(), parseISO(session.session_date))) {
      buttons.push(
        <Button key="survey_button" className="has-text-success-dark" onClick={() => showSessionOrOffboardingModal(sessionNumToShow, true, !surveyCompleted)} outlined>
        <span className="icon">
          <FontAwesomeIcon icon={faPoll} />
        </span>
        <span>{ surveyCompleted ? 'View' : 'Take'} Survey</span>
      </Button>
      )
    }
    
    if (!session.completed && !(sessionNumToShow === sessions.length-1 && props.offboardingCompletedOn)) {
      if (!session.session_date) {
        // Return a schedule button
        buttons.push(
          <Button key="schedule_button" onClick={() => setShowSessionModal({ session: sessionNumToShow, forSurvey: false})} color="link">
          <span className="icon">
            <FontAwesomeIcon icon={faCalendarAlt} />
          </span>
          <span>Schedule</span>
        </Button>
        )
      }
      else {
        buttons.push(
          <Button key="schedule_button" onClick={() => setShowSessionModal({ session: sessionNumToShow, forSurvey: false})} outlined color="link">
          <span className="icon">
            <FontAwesomeIcon icon={faCalendarAlt} />
          </span>
          <span>Reschedule</span>
        </Button>)
      }
    }

    return <Button.Group>{ buttons.map(button => button) }</Button.Group>;
  }

  function checkSessionTime(session, sessionDate) {
    const sessionNum = session.session_num;
    const beforeSessions = sessions.slice(0, sessionNum - 1);
    const afterSessions = sessions.slice(sessionNum);

    const beforeSessionDate = beforeSessions.length > 0 ? parseISO(beforeSessions[beforeSessions.length-1].session_date) : null;
    const afterSession = afterSessions.length > 0 ? parseISO(afterSessions[0].session_date) : null;

    const hasSessionBefore = beforeSessionDate && (isBefore(sessionDate, beforeSessionDate) || isEqual(sessionDate, beforeSessionDate));
    const hasSessionAfter = afterSession > 0 && (isAfter(sessionDate, afterSession) || isEqual(sessionDate, afterSession));
    const isOver12Weeks = sessions[0].session_date && differenceInWeeks(sessionDate, parseISO(sessions[0].session_date)) > mentorshipDuration;

    if (hasSessionBefore || hasSessionAfter) {
      return "You have a later session that is scheduled before this one.  Please change this or the other session's schedule";
    }
    else if (isOver12Weeks) {
      return `The session date must be within ${mentorshipDuration} weeks of your first session.`;
    }

    return '';
  }

  function getSessionSteps() {
    return <div className="px-2">
    <ol className="upcoming-events">
      { sessions.sort((a,b) => a.session_num - b.session_num).map((session, idx) => 
        <li key={`session_${session.id}`} className="my-3">
          <div>
            <Heading className="mb-0" size={5}><a onClick={() => setShowSessionModal({ session: idx, forSurvey: false})}>{session.title}</a></Heading>
            { session.session_date && session.completed && <span>Completed on {format(parseISO(session.session_date), 'PPPp')}.</span> }
            { session.session_date && !session.completed && <div><span>Scheduled for {format(parseISO(session.session_date), 'PPPp')}.</span> {session.notes && <a onClick={() => setShowSessionNotesModal(session)}>  View notes...</a> } { isAfter(new Date(), parseISO(session.session_date)) &&  <span className='tag is-warning'>Past due</span> } </div> }
          </div>
          
          { getButtons(session) }
        </li>
      )}
    </ol>
  </div>
  }

  function getContent() {
    if (sessions) {
      return getSessionSteps();
    }
    else {
      return <div className="px-2 has-text-grey">
      { props.isMentor ? 
        <>Your schedules, events and task items will appear here.</>
      :
        <>Once you have been matched you'll be asked to setup a meeting.  Your schedules, events and task items will appear here.</>
      }
    </div>
    }

  }

  return (
    <Card>
      <Card.Content>
        <div className="is-flex is-align-items-center">
          <div className="px-4">
            <FontAwesomeIcon className="has-text-info" size="3x" icon={faCalendarAlt} />
          </div>

          <div>
            <Heading>Upcoming Events</Heading>
            <Heading subtitle>
              Meetings, commitments and tasks<br/>
              <a onClick={() => setShowRulesOfEngagementModal(true)}>Engagement Etiquette</a>
            </Heading>
          </div>
        </div>

        <hr/>

        { getContent() }

      </Card.Content>

      <RulesOfEngagmentModal open={showRulesOfEngagementModal} user={props.user} onClose={() => setShowRulesOfEngagementModal(false)} />
      { showSessionModal !== null &&
        <SessionModal groupId={state.user.groupId} isMentor={props.isMentor} open={showSessionModal !== null} onCheckSessionTime={checkSessionTime} onClose={() => setShowSessionModal(null)} isCompleting={showSessionModal.isCompleting} forSurvey={showSessionModal.forSurvey} session={showSessionModal.session !== null ? sessions[showSessionModal.session] : null} />
      }

      { showSessionNotesModal && <SessionNotesModal open={showSessionNotesModal !== null} session={showSessionNotesModal} onClose={() => setShowSessionNotesModal(null)} /> }

    </Card>);
}

export default UpcomingEvents;