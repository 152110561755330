import ReactTooltip from 'react-tooltip';
import { useState } from 'react';
import { useStore } from '../../store/Store';
import { Columns, Form, Heading } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { alphaSort99 } from '../../lib/formHelpers';
import { joiResolver } from '@hookform/resolvers/joi';
import { emptySchemaUI } from '../../schemas';
import { patchUser } from '../../lib/api';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OnboardingWizardItem from '../OnboardingWizardItem/OnboardingWizardItem';

function CreateProfilePersonal(props) {
  const { state, dispatch } = useStore();

  const [selectedIndentities, setSelectedIndentities] = useState(getInitialSelectedIdentities());
  const [otherGender, setOtherGender] = useState(state.user?.infoSheet?.otherGender || '');
  const [otherRace, setOtherRace] = useState(state.user?.infoSheet?.otherRace || '');
  const [preferredPronouns, setPreferredPronouns] = useState(state.user?.preferredPronouns || '');
  
  function getInitialSelectedIdentities() {
    if (state.user) {
      return state.user.identities.map((e) => {
        return e.id;
      })
    }
    return [];
  }

  const availableMinorities = [...state.staticValues.minorities.sort(alphaSort99)];
  const availableGenders = [...state.staticValues.genders.sort(alphaSort99)];
  const availableOtherIdentities = [...state.staticValues.other_identities.sort(alphaSort99)];

  const formParams = {
    resolver: joiResolver(emptySchemaUI),
    defaultValues: {
      // otherIdentity: state.user?.otherIdentity || '',
    }
  }

  const { /*register,*/ handleSubmit } = useForm(formParams);

  async function onSubmit(data) {
    try {
      const updatedData = {
        identities: selectedIndentities,
        otherGender: otherGender,
        otherRace: otherRace,
        preferredPronouns: preferredPronouns,
      }
      await patchUser(props.isMentor, state.user, updatedData, dispatch);
      props.nav.onNext();
    }
    catch (err) {
      console.log('Personal Info err', err);
    }
  }

  function hasIdentity(identity) {
    return selectedIndentities.includes(identity);
  }

  function toggleIdentity(identity, hasIdentity) {
    if (hasIdentity) {
      setSelectedIndentities([...selectedIndentities, identity]);
    }
    else {
      setSelectedIndentities(selectedIndentities.filter(e => e !== identity));
    }
    console.log(selectedIndentities);
    return selectedIndentities.includes(identity);
  }

  const description = `Please tell us a little more about you.  This information helps us to pair you more accurately with a ${props.isMentor ? 'Mentee' : 'Mentor'} based on their personal preference and professional relationship goals.`;
  return (
    <OnboardingWizardItem title="Personal Info" description={description} handleSubmit={handleSubmit(onSubmit)} {...props.nav}>
      <ReactTooltip />

      <p className="has-text-centered mb-5">By answering this section, your responses may be used as criteria to match you in a Focused Match, should this be of importance to the Mentor/Mentee. If you choose to not respond to this section, nothing denoting these categories will be shared and your match will be more randomized.
        <span data-tip="Gender and race information compiled from US Census 2020, World Atlas &amp; Statista" className="has-text-info" style={{ cursor: 'pointer', paddingLeft: 7 }}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </p>
      <Columns className="mx-3">

        <Columns.Column className="is-two-fifths">
          <Heading size="6" className="mb-1">Race/Ethnicity</Heading>
          {availableMinorities.map((identity) =>
            <Form.Field key={`Identity_${identity.id}`}>
              <Form.Checkbox checked={hasIdentity(identity.id)} onChange={e => toggleIdentity(identity.id, e.target.checked)}>{identity.description}</Form.Checkbox>
            </Form.Field>)}

          <Form.Field>
            <Form.Label style={{ fontWeight: 'normal' }}>Write in:</Form.Label>
            <Form.Field.Body>
              <Form.Field>
                <Form.Control>
                  <Form.Input value={otherRace} onChange={e => setOtherRace(e.target.value)} ></Form.Input>
                </Form.Control>
              </Form.Field>
            </Form.Field.Body>
          </Form.Field>

        </Columns.Column>

        <Columns.Column>
          <Heading size="6" className="mb-1">Gender</Heading>
          {availableGenders.map((identity) =>
            <Form.Field key={`Identity_${identity.id}`}>
              <Form.Checkbox checked={hasIdentity(identity.id)} onChange={e => toggleIdentity(identity.id, e.target.checked)}>{identity.description}</Form.Checkbox>
            </Form.Field>)}
          <Form.Field>
            <Form.Checkbox className="is-invisible">Placeholder</Form.Checkbox>
          </Form.Field>

          <Form.Field>
            <Form.Label style={{ fontWeight: 'normal' }}>Write in:</Form.Label>
            <Form.Field.Body>
              <Form.Field>
                <Form.Control>
                  <Form.Input value={otherGender} onChange={e => setOtherGender(e.target.value)} ></Form.Input>
                </Form.Control>
              </Form.Field>
            </Form.Field.Body>
          </Form.Field>
        </Columns.Column>

        <Columns.Column>
          <Heading size="6" className="mb-1">Additional Identifications</Heading>
          {availableOtherIdentities.map((identity) =>
            <Form.Field key={`Identity_${identity.id}`}>
              <Form.Checkbox checked={hasIdentity(identity.id)} onChange={e => toggleIdentity(identity.id, e.target.checked)}>{identity.description}</Form.Checkbox>
            </Form.Field>)}

            <Form.Field>
            <Form.Checkbox className="is-invisible">Placeholder</Form.Checkbox>
            </Form.Field>
            <Form.Field>
            <Form.Checkbox className="is-invisible">Placeholder</Form.Checkbox>
            </Form.Field>
            <Form.Field>
            <Form.Checkbox className="is-invisible">Placeholder</Form.Checkbox>
            </Form.Field>
            <Form.Field>
            <Form.Checkbox className="is-invisible">Placeholder</Form.Checkbox>
            </Form.Field>
            <Form.Field>
            <Form.Checkbox className="is-invisible">Placeholder</Form.Checkbox>
            </Form.Field>

            <Form.Field>
            <Form.Label style={{ fontWeight: 'bold' }}>Preferred Pronouns:</Form.Label>
            <Form.Field.Body>
              <Form.Field>
                <Form.Control>
                  <Form.Input value={preferredPronouns} onChange={e => setPreferredPronouns(e.target.value)} ></Form.Input>
                </Form.Control>
              </Form.Field>
            </Form.Field.Body>
          </Form.Field>
        </Columns.Column>
      </Columns>
    </OnboardingWizardItem>
  )
}
export default CreateProfilePersonal;