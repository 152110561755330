import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { emptySchemaUI } from '../../../schemas';
import OnboardingWizardItem from '../../OnboardingWizardItem/OnboardingWizardItem';
import { useStore } from '../../../store/Store';
import { patchUser } from '../../../lib/api';

function InfoSheetComplete(props) {
  const { state, dispatch } = useStore();
  const history = useHistory();

  const formParams = {
    resolver: joiResolver(emptySchemaUI),
  }
  
  function onSubmit() {
    async function updateUser() {

      try {
        const updatedData = {
          wizard2Page: 99,
        }

        await patchUser(props.isMentor, state.user, updatedData, dispatch);

        history.push('/');
      }
      catch (err) {
        console.log('InfoSheet Complete err', err);
      }
    }

    updateUser();

  };

  const { handleSubmit } = useForm(formParams);

  return (
    <OnboardingWizardItem title="Thank you!" description="You're all set!  We'll send you back to your dashboard to fill you in with next steps!"   handleSubmit={handleSubmit(onSubmit)} {...props.nav}>

    </OnboardingWizardItem>

  )
}

export default InfoSheetComplete;