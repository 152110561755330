import { Heading, Card } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { isAfter, isBefore, parseISO, format } from 'date-fns';
import TodoItem from '../../../../components/TodoItem/TodoItem';

function Roadmap(props) {
  const profileTypeId = props.user?.activeProfile?.contactType?.id;

  let deadline = null;
  if (props.user?.group?.offboardingDate) {
    try {
      deadline = parseISO(props.user?.group?.offboardingDate);
    }
    catch { }
  }

  const PersonalProfileToDo = () => {
    if (props.hasOnboardingQuestions) {
      return <TodoItem title="Complete your personal profile" description="Let us get to know you better by filling out your personal profile." unlocked={!props.profileComplete} completed={props.profileComplete} linkTo='/onboarding' />
    }
    else {
      return <TodoItem title="Complete your personal profile" description="This cohort's onboarding questions are not available yet.  Please check back later to complete your profile." unlocked={false} completed={false} />
    }
  }

  const OffboardingToDo = () => {
    const completeOffboarding = <TodoItem title="Complete the offboarding survey" description="Complete the final survey about your experience." unlocked={true} completed={props.user.offboardingCompletedOn} onClick={props.onStartOffboarding} />;

    if (deadline) {
      const lockedOffboarding = <TodoItem title="Complete the offboarding survey" description={`Will be available ${format(deadline, 'PP')}`} locked={true} unlocked={false} completed={false} />

      if ((props.mentorshipCompletedOn && props.user.group.allowOffboardOnCompletion) || isAfter(new Date(), deadline) || props.offboardingAvailable) {
        return completeOffboarding;
      }
      else {
        return lockedOffboarding;
      }
    }
    else if (props.user.offboardingCompletedOn || props.offboardingAvailable) {
      return completeOffboarding;
    }

    return <></>
  }

  function GenPopMenteeRoapmapItems() {
    if (props.user.matchType === 'random') {
      return <>
        <PersonalProfileToDo />
        <TodoItem title="Upload your profile picture" description="Upload a picture of yourself" unlocked={props.profileComplete} locked={!props.profileComplete} completed={props.user.profilePic} onClick={props.onUploadProfile} />
        {/* <TodoItem title="Setup your account" description="Setup your TERN Mentoring account to unlock services and benefits." unlocked={!accountIsActive} completed={accountIsActive} /> */}
        {/* <TodoItem title="Get Matched!" description="TERN Mentoring is working on matching you with your ideal Mentor." unlocked={canMatch} locked={!canMatch} /> */}
      </>
    }
    else {
      return <>
        <PersonalProfileToDo />
        {/* <TodoItem title="Take the Enneagram" description="Take the Enneagram and provide us with your score to help us direct the best resources to you." locked={!props.profileComplete} completed={props.ennegramComplete} unlocked={props.profileComplete} linkTo="/enneagram" /> */}
        <TodoItem title="Complete the Info Sheet" description="Tell us about your background and experiences to find the best Mentor for you." unlocked={props.ennegramComplete} locked={!props.ennegramComplete} completed={props.infoSheetComplete} linkTo="/infosheet" />
        <TodoItem title="Upload your profile picture" description="Upload a picture of yourself" unlocked={props.profileComplete} locked={!props.profileComplete} completed={props.user.profilePic} onClick={props.onUploadProfile} />
        {/* <TodoItem title="Setup your account" description="Setup your TERN Mentoring account to unlock services and benefits." unlocked={!accountIsActive} completed={accountIsActive} /> */}
        {/* <TodoItem title="Get Matched!" description="TERN Mentoring will use the information you provide to match you with your ideal Mentor." unlocked={canMatch} locked={!canMatch} /> */}
      </>
    }
  }

  function GenPopMentorRoapmapItems() {
    return <>
      {/* <TodoItem title="Make payment" description="Make a payment to complete your mentorship subscription." completed={props.hasSubscription} unlocked={!props.hasSubscription} linkTo={!props.hasSubscription ? '/payment' : ''} /> */}
      {/* <TodoItem title="Complete your personal profile" description="Let us get to know you better by filling out your personal profile." completed={props.profileComplete} unlocked={props.hasSubscription} locked={!props.hasSubscription} linkTo='/onboarding' /> */}
      <PersonalProfileToDo />
      {/* <TodoItem title="Take the Enneagram" description="Take the Enneagram and provide us with your score to help us direct the best resources to you." locked={!props.profileComplete} completed={props.ennegramComplete} unlocked={props.profileComplete} linkTo="/enneagram" /> */}
      <TodoItem title="Complete the Info Sheet" description="Tell us about your background and experiences to find the best Mentee for you." unlocked={props.ennegramComplete} locked={!props.ennegramComplete} completed={props.infoSheetComplete} linkTo="/infosheet" />
      <TodoItem title="Upload your profile picture" description="Upload a picture of yourself" unlocked={props.infoSheetComplete} locked={!props.infoSheetComplete} completed={props.user.profilePic} onClick={props.onUploadProfile} />
      {/* <TodoItem title="Setup your account" description="Setup your TERN Mentoring account to unlock services and benefits." unlocked={!accountIsActive} completed={accountIsActive} /> */}
      {/* <TodoItem title="Get Matched!" description="TERN Mentoring will use the information you provide to match you with your ideal Mentee." unlocked={canMatch} locked={!canMatch} /> */}
    </>
  }

  function CompanyMenteeRoapmapItems() {
    return <>
      <PersonalProfileToDo />
      <TodoItem title="Upload your profile picture" description="Upload a picture of yourself" unlocked={true} completed={props.user.profilePic} onClick={props.onUploadProfile} />
      {/* <TodoItem title="Complete the offboarding survey" description="Complete the final survey about your experience." unlocked={true} completed={props.user.offboardingCompletedOn} onClick={props.onStartOffboarding} /> */}
      {/* <TodoItem title="Setup your account" description="Setup your TERN Mentoring account to unlock services and benefits." unlocked={!accountIsActive} completed={accountIsActive} /> */}
      {/* <TodoItem title="Get Matched!" description="TERN Mentoring is working on matching you with your ideal Mentor." unlocked={canMatch} locked={!canMatch} /> */}
      <OffboardingToDo />    
    </>
  }

  function CompanyMentorRoapmapItems() {
    return <>
      <PersonalProfileToDo />
      <TodoItem title="Upload your profile picture" description="Upload a picture of yourself" unlocked={true} completed={props.user.profilePic} onClick={props.onUploadProfile} />
      <OffboardingToDo />    
    </>
  }

  function getRoadmapItems() {
    if (props.mentorshipCompletedOn || props.offboardingAvailable) {
      return <OffboardingToDo />
    }
    else {
      if (profileTypeId === 1) {
        // Mentee
        if (props.user.group.id === 1) return GenPopMenteeRoapmapItems();
        else return CompanyMenteeRoapmapItems();
      }
      else {
        // Mentor

        if (props.user.group.id === 1) return GenPopMentorRoapmapItems();
        else return CompanyMentorRoapmapItems();
      }
    }
  }

  return (
    <Card>
      <Card.Content>
        <div className="is-flex is-align-items-center">
          <div className="px-4">
            { props.mentorshipCompletedOn || props.offboardingAvailable ? 
              <FontAwesomeIcon className="has-text-info" size="3x" icon={faTrophy} />
            :
              <FontAwesomeIcon className="has-text-info" size="3x" icon={faRoad} />
            }
          </div>

          { props.offboardingCompletedOn ? 
            <div>
              <Heading>Congratulations {props.user.firstName}!</Heading>
              <Heading subtitle>
                You've completed your Mentorship journey!
              </Heading>
            </div>
          : props.offboardingAvailable ? 
            <div>
              <Heading>Congratulations {props.user.firstName}!</Heading>
              <Heading subtitle>
              Almost there! Just one thing remains to close out for this semester, the offboarding survey, which is critical for program enhancements and mentorship research. Thank you for completing this survey!
              </Heading>
            </div>
          :
            <div>
              <Heading>Welcome {props.user.firstName}!</Heading>
              <Heading subtitle>
                Follow this roadmap to get started!
              </Heading>
            </div>
          }

        </div>

        <hr/>
  
        { props.noItems ? 
          <div className="px-2 has-text-grey">Your roadmap is complete!</div>
        : 
          getRoadmapItems() 
        }
      </Card.Content>
    </Card>
  )

}

export default Roadmap;