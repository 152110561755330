import { useState } from 'react';
import { useStore } from '../../store/Store';
import { emptySchemaUI } from '../../schemas';
import { patchUser } from '../../lib/api';
import { Form} from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { alphaSort99 } from '../../lib/formHelpers';
import OnboardingWizardItem from '../OnboardingWizardItem/OnboardingWizardItem';

function AffinityGroups(props) {
  const { state, dispatch } = useStore();

  const [selectedAffinities, setSelectedAffinities] = useState(state.user?.infoSheet?.affinityGroups ? [...state.user.infoSheet.affinityGroups.map((m) => m.id)] : []);
  const [otherAffinity, setOtherAffinity] = useState(state.user?.infoSheet?.otherAffinityGroup || '');
  const affinities = [...state.staticValues.affinityGroups]

  const formParams = {
    resolver: joiResolver(emptySchemaUI),
  }
  const { handleSubmit } = useForm(formParams);


  function hasAffinity(affinity) {
    return selectedAffinities.includes(affinity);
  }

  function toggleInterest(affinity, hasAffinity) {
    if (hasAffinity) {
      setSelectedAffinities([...selectedAffinities, affinity]);
    }
    else {
      setSelectedAffinities(selectedAffinities.filter(e => e !== affinity));
    }
    return selectedAffinities.includes(affinity);
  }

  async function onSubmit(/*data*/) {

    try {
      const updatedData = {
        affinityGroups: [...selectedAffinities],
        otherAffinityGroup: otherAffinity,
      }

      await patchUser(props.isMentor, state.user, updatedData, dispatch);
      
      props.nav.onNext();
    }
    catch (err) {
      console.error('Affinity Group err:', err);
    }
  }

  return(
    <OnboardingWizardItem title="Clubs, Organizations, Networks and Affinity Groups" description="Please select all of the clubs, organizations, networks and Affinity Groups you are or have been a member of." handleSubmit={handleSubmit(onSubmit)} {...props.nav}>

      <div className="pt-2" style={{display: 'flex', height: '21rem', flexDirection: 'column', flexWrap: 'wrap'}}>

        { affinities.sort(alphaSort99).map((affinity) => 
          <Form.Field key={`Affinity_${affinity.id}`} style={{width: '33%'}}>
            <Form.Checkbox checked={hasAffinity(affinity.id)} onChange={e => toggleInterest(affinity.id, e.target.checked)}>{affinity.description}</Form.Checkbox>
          </Form.Field>)}
        
      </div>

      <Form.Field horizontal className="py-3">
        <Form.Field.Label className="is-normal" style={{ flexGrow: 0 }}>
          <Form.Label className="label">Other:</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Input value={otherAffinity} onChange={e => setOtherAffinity(e.target.value)} ></Form.Input>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

  </OnboardingWizardItem>
  )




}

export default AffinityGroups;