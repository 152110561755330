import './MentorMenteeBox.scss';
import { Heading, Box, Block } from 'react-bulma-components';
import { Link } from "react-router-dom";
import { faSearch, faRandom } from '@fortawesome/free-solid-svg-icons';
import ContactInfo from '../ContactInfo/ContactInfo';
import ProfilePicture from '../ProfilePicture/ProfilePicture';

function MentorMenteeBox(props) {

  const title = props.isMentor ? 'Your Mentee' : 'Your Mentor';
  const fullName = props.profile ? `${props.profile?.salutation ? props.profile.salutation : ''} ${props.profile?.firstName} ${props.profile?.lastName}`.trim() : `Awaiting Match`;

  function getCallToAction() {
    if (props.needPayment) {
      return <><div className="has-text-weight-semibold">Make your payment of $99 to start your Mentorship with your Mentor!</div>
      <button className="mt-3 button is-success" onClick={() => props.onMakePayment() }>Make Payment</button>
      </>
    }
    else {
      return (
        <Heading size="6" className="has-text-grey">
          {props.canMatch ? 'Awaiting Match' : 'Please complete the roadmap to start the matching process'}
        </Heading>)
    }
  }

  function getMatchTypeSelector() {
    const statusText = '';//props.isMatching ? "We're getting things ready to start the match process.  We'll send you an email with a status update soon!"
      //: "Once you've completed the steps below we'll get started on the ideal match for you.";

    const info = {
      title: 'Focused Match',
      desc: `You've selected a focused match. ${statusText}`,
      icon: faSearch
    }

    if (props.matchType === 'random') {
      info.title = 'Random Match';
      info.desc = `You've selected a random match. ${statusText}`;
      info.icon = faRandom;
    }

    return (
      <div className="is-text-center" style={{textAlign: 'center'}}>
        {/* <div>
          <FontAwesomeIcon className="mr-2 has-text-info" size="1x" icon={faSearch} />
          <Heading style={{display: 'inline-block'}} size="5">
            {info.title}
          </Heading>
        </div> */}
        <div>
          {info.desc}
        </div>
        {/* <div className="px-4">
          <Button className="mt-2 is-info" onClick={props.onChangeMatchType}>CHANGE</Button>
        </div> */}
      </div>
    );
  }
  console.log('props.profile', props.profile)

  return (
    <Box>
      <Heading>{title}</Heading>

      <div className="is-flex is-justify-content-center">
        <div className="mentor-mentee-box-avatar-container">
          <ProfilePicture isMentor={props.isMentor} picture={props.profile?.profilePic} />
        </div>
      </div>

      <Block className="mt-2 has-text-centered">
        {props.profile ? <>
          <Heading className="mt-2 has-text-centered" size="5">
            <Link to="/matched/profile">{fullName}</Link>
          </Heading>

          <ContactInfo profile={props.profile} />
        </>
          :
          <>{ getCallToAction() }</>
        }
      </Block>

      {!props.profile && !props.isMentor && !props.needPayment && getMatchTypeSelector()}

    </Box>
  )
}

export default MentorMenteeBox;