import OnboardingScreen from '../../screens/OnboardingScreen/OnboardingScreen.js';
import InfoSheetEnneagram from '../../components/InfoSheet/InfoSheetEnneagram/InfoSheetEnneagram.js';

function EnneagramWizard() {
  
  const Pages = [
    [InfoSheetEnneagram, null],
  ]

  return (
    <OnboardingScreen Pages={Pages} />
  );
}

export default EnneagramWizard;
