import './OnboardingScreen.scss';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import ProgressComponent from '../../components/ProgressComponent/ProgressComponent';

function OnboardingScreen(props) {
  const history = useHistory();
  const [pageNo, setPageNo] = useState(0);
  const [slideClass, setSlideClass] = useState('fade-and-slide-left');

  const nodeRef = React.useRef(null);

  console.debug('This is page', pageNo);

  const canGoNext = pageNo !== props.Pages.length - 1;
  const canGoBack = pageNo > 0;

  function back() {
    if (pageNo > 0) {
      setPageNo(pageNo - 1);
      setSlideClass('fade-and-slide-right');
    }
  }

  function next() {
    if (canGoNext) {
      setPageNo(pageNo + 1);
      setSlideClass('fade-and-slide-left');
    }
    else {
      history.push('/');
    }
  }

  function getPage() {
    const pageProps = {
      isMentor: props.isMentor,
      ...props.Pages[pageNo][1],
      nav: {
        ...props.nav,
        onBack: back,
        onNext: next,
        canGoNext: canGoNext,
        canGoBack: canGoBack,
        pageNo: pageNo,
        totalPages: props.Pages.length,
      }
    }

    const Component = props.Pages[pageNo][0];
    return  <Component {...pageProps} />
    

  }

  

  function progress () { 
    const now = pageNo + 1; 
    const totalPages = props.Pages.length;
    return (
    <div className="mb-5 container is-max-desktop">
      <ProgressComponent value={now} label={`Step ${now} of ${totalPages}`} max = {totalPages} />
    </div>
    )
  }




  return (<>
    <div className="is-fullheight onboarding-screen-background" />
    <div>
      
      
      <section className="">

        <div style={{marginTop: 90}}>

          <div className="container is-max-desktop">

            { (props.ShowProgress === undefined || props.ShowProgress) && progress()}

            <SwitchTransition mode="out-in">
            
              <CSSTransition
                key={pageNo}
                classNames={slideClass}
                timeout={500}
                nodeRef={nodeRef}
              > 
                <div ref={nodeRef}>
                  { props.isLoading ? <progress className="progress is-small is-info" max="100"></progress> 
                    : <>{getPage()}</>
                  }
                </div>
              </CSSTransition>
              
              
            </SwitchTransition>
            <br/>

            
          </div>
          
          
        </div>
        
      </section>
    </div>
    
    </>
  );
}

export default OnboardingScreen;