import { Modal } from 'react-bulma-components';
import { useState, useRef } from 'react';
import MentorshipFeedbackComponent from '../MentorshipFeedbackComponent/MentorshipFeedbackComponent';

function MentorshipFeedbackModal(props) {
  const childRef = useRef(null);
  const title = 'Mentorship Survey';
  const [isCompleting, setIsCompleting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  function completionStateChanged(newState) {
    setIsCompleting(newState);
  }

  function onFormValidChange(isValid) {
    setIsFormValid(isValid);
  }

  async function close() {
    const doneResult = await childRef.current.onDoneClicked();
    if (doneResult) {
      if (props.onClose) {
        props.onClose();
      }
      else {
        console.error('No onClose function provided');
      }
    }
  }

  return (
    <Modal show={props.open} onClose={() => props.onClose()}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">{title}</p>
        </Modal.Card.Header>
          <Modal.Card.Body>
            <MentorshipFeedbackComponent isMentor={props.isMentor} ref={childRef} mentorship={props.mentorship} showTitle={false} 
              completionStateChanged={completionStateChanged} isCompleting={isCompleting} 
              formValidChange={onFormValidChange} formValid={isFormValid}
              />
          </Modal.Card.Body>
          <Modal.Card.Footer className="modal-card-foot field is-grouped is-grouped-right">
            { isCompleting && <button className="button" onClick={() => props.onClose()}>Cancel</button> }
            <button className="button is-success" disabled={!isFormValid} onClick={close}>Done</button>
          </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default MentorshipFeedbackModal;