import OnboardingScreen from '../../screens/OnboardingScreen/OnboardingScreen.js';
import CreateProfileGetStarted from '../../components/CreateProfileGetStarted/CreateProfileGetStarted';
import CreateProfileDetails from '../../components/CreateProfileDetails/CreateProfileDetails';
// import CreateProfileRole from '../../components/CreateProfileRole/CreateProfileRole';
// import CreateProfileEducationInfo from '../../components/CreateProfileEducationInfo/CreateProfileEducationInfo';
// import CreateProfilePersonal from '../../components/CreateProfilePersonal/CreateProfilePersonal';
// import LanguagePage from '../../components/Languages/Languages.js';
// import CreateProfileComplete from '../../components/CreateProfileComplete/CreateProfileComplete';
// import AffinityGroups from '../../components/AffinityGroups/AffinityGroups.js';

function MentorOnboarding() {
  const Pages = [
    [CreateProfileGetStarted, null],
    [CreateProfileDetails, null],
    // [CreateProfileRole, null],
    // [CreateProfileEducationInfo, null],
    // [AffinityGroups, null],
    // [CreateProfilePersonal, null],
    // [LanguagePage, null],
    // [CreateProfileComplete, null],
  ]

  return (
    <OnboardingScreen Pages={Pages} isMentor={true} ShowProgress={false} />
  );
}

export default MentorOnboarding;