import { Modal } from 'react-bulma-components';
import Linkify from '../../Linkify/Linkify';
function SessionNotesModal({open, onClose, session}) {

  return (
    <Modal show={open} onClose={() => onClose()}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">Meeting notes</p>
        </Modal.Card.Header>
          <Modal.Card.Body>
            <div className="mb-3 has-text-weight-bold">Meeting notes for {session.title}</div>
            <Linkify>{ session.notes }</Linkify>
          </Modal.Card.Body>
          <Modal.Card.Footer className="modal-card-foot field is-grouped is-grouped-right">
            <button className="button is-success" onClick={() => onClose()}>Done</button>
          </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default SessionNotesModal;