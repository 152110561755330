import { useState } from 'react';
import { useStore } from '../../../store/Store';
import { Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { enneagramUI } from '../../../schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import { patchUser } from '../../../lib/api';

import OnboardingWizardItem from '../../OnboardingWizardItem/OnboardingWizardItem';
import EnneagramModal from '../../../components/EnneagramModal/EnneagramModal';

import classNames from 'classnames';

function InfoSheetEnneagram(props) {
  const { state, dispatch } = useStore();
  const [showModal, setShowModal] = useState(false);

  const choices = [
    { id: 0, title: 'Select...', desc: '' },
    { id: 1, title: '1 The Reformer', desc: 'The Rational, Idealistic Type: Principled, Purposeful, Self-Controlled, and Perfectionistic' },
    { id: 2, title: '2 The Helper', desc: 'The Caring, Interpersonal Type: Demonstrative, Generous, People-Pleasing, and Possessive' },
    { id: 3, title: '3 The Achiever', desc: 'The Success-Oriented, Pragmatic Type: Adaptive, Excelling, Driven, and Image-Conscious' },
    { id: 4, title: '4 The Individualist', desc: 'The Sensitive, Withdrawn Type: Expressive, Dramatic, Self-Absorbed, and Temperamental' },
    { id: 5, title: '5 The Investigator', desc: 'The Intense, Cerebral Type: Perceptive, Innovative, Secretive, and Isolated' },
    { id: 6, title: '6 The Loyalist', desc: 'The Committed, Security-Oriented Type: Engaging, Responsible, Anxious, and Suspicious' },
    { id: 7, title: '7 The Enthusiast', desc: 'The Busy, Fun-Loving Type: Spontaneous, Versatile, Distractible, and Scattered' },
    { id: 8, title: '8 The Challenger', desc: 'The Powerful, Dominating Type: Self-Confident, Decisive, Willful, and Confrontational' },
    { id: 9, title: '9 The Peacemaker', desc: 'The Easygoing, Self-Effacing Type: Receptive, Reassuring, Agreeable, and Complacent' },
  ];

  const [selectedChoice, setSelectedChoice] = useState(state.user?.infoSheet?.enneagram?.id?.toString() || '0');
  const [enneagramScoreError, setEnneagramScoreError] = useState('');

  const formParams = {
    resolver: joiResolver(enneagramUI),
  }

  const { handleSubmit } = useForm(formParams);

  function selectionChanged(newSelection) {
    setEnneagramScoreError('');
    setSelectedChoice(newSelection);
  }

  async function onSubmit(data) {
    if (selectedChoice === '0') {
      setEnneagramScoreError('Required');
    }
    else {
      const updatedData = {
        enneagramScoreId: selectedChoice,
      }

      try {
        await patchUser(props.isMentor, state.user, updatedData, dispatch);

        props.nav.onNext();
      }
      catch (err) {
        console.error('Enneagram err', err);
      }
    }
  }

  return (
    <OnboardingWizardItem title="Enneagram" description="The Enneagram is a system of personality typing that describes patterns in how people interpret the world and manage their emotions. The Enneagram describes nine personality types and maps each of these types on a nine-pointed diagram which helps to illustrate how the types relate to one another." handleSubmit={handleSubmit(onSubmit)} {...props.nav}>

      <p className="has-text-centered pb-3">You can use the link below to find your Enneagram type for free (it'll open in a new window so you won't lose your place here):</p>
      <p className="has-text-centered pb-3"><a className="button has-text-white has-background-info" href="https://www.truity.com/test/enneagram-personality-test" rel="noreferrer" target="_blank">Take The Enneagram</a></p>
      <p className="has-text-centered pb-3"><a href="http://assets.ternmentoring.com/web/Understanding_the_Enneagram.pdf" rel="noreferrer" target="_blank">Learn more about Enneagrams&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
      <p className="has-text-centered pb-3">Once you've completed the test, return here and select your Enneagram number below:</p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>

        <Form.Field style={{ width: 400 }}>
          {/* <Form.Label>Enneagram Number</Form.Label> */}
          <Form.Control>
            <Form.Label>
              Enneagram Number
              <span className="has-text-info" style={{ cursor: 'pointer', paddingLeft: 7 }}>
                <FontAwesomeIcon icon={faInfoCircle} onClick={() => setShowModal(true)} />
              </span>
            </Form.Label>
            <Form.Select className={classNames('is-fullwidth', { 'is-danger': enneagramScoreError })} value={selectedChoice} onChange={(e) => selectionChanged(e.target.value)}>
              {choices.map((theChoice) =>
                <option key={`enneagram_${theChoice.id}`} value={theChoice.id}>
                  {theChoice.title}
                </option>
              )}
            </Form.Select>
            <Form.Help color={enneagramScoreError ? 'danger' : ''}>{enneagramScoreError ? enneagramScoreError : choices.find(e => e.id === parseInt(selectedChoice)).desc}</Form.Help>
          </Form.Control>
        </Form.Field>
      </div>

      <EnneagramModal open={showModal} onClose={() => setShowModal(false)} />

    </OnboardingWizardItem>

  )
}

export default InfoSheetEnneagram;