import { useLocation } from 'react-router-dom';
import OnboardingScreen from '../../screens/OnboardingScreen/OnboardingScreen.js';
// import InfoSheetHobbies from '../../components/InfoSheet/InfoSheetHobbies/InfoSheetHobbies.js';
import InfoSheetPersonality from '../../components/InfoSheet/InfoSheetPersonality/InfoSheetPersonality.js';
import InfoSheetPersonalInterests from '../../components/InfoSheet/InfoSheetPersonalInterests/InfoSheetPersonalInterests';
import InfoSheetBusinessInterests from '../../components/InfoSheet/InfoSheetBusinessInterests/InfoSheetBusinessInterests';
import MenteeGoals from '../../components/MenteeGoals/MenteeGoals.js'
import MenteeGoals2 from '../../components/MenteeGoals2/MenteeGoals2.js'
import InfoSheetRecommendations from '../../components/InfoSheet/InfoSheetRecommendations/InfoSheetRecommendations';
import InfoSheetComplete from '../../components/InfoSheet/InfoSheetComplete/InfoSheetComplete';

function MenteeInfoSheetWizard() {
  const redirectLink = new URLSearchParams(useLocation().search).get('r') || '';

  let Pages = [
    [InfoSheetPersonality, null],
    [InfoSheetPersonalInterests, null],
    [InfoSheetBusinessInterests, null],
    [MenteeGoals,null],
    [MenteeGoals2, null],
    [InfoSheetRecommendations, null], // Keep this one last - it has a selective redirect
  ]

  if (!redirectLink) {
    Pages.push([InfoSheetComplete, null])
  }

  return (
    <OnboardingScreen Pages={Pages} isMentor={false} />
  );
}

export default MenteeInfoSheetWizard;
