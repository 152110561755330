import { useState } from 'react';
import { Heading, Card } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, } from '@fortawesome/free-solid-svg-icons';
import RulesOfEngagmentModal from '../../../../components/RulesOfEngagmentModal/RulesOfEngagmentModal';

function Resources(props) {
  const [showRulesOfEngagementModal, setShowRulesOfEngagementModal] = useState(false);

  function RenderResources() {
    if (props.user.resources && props.user.resources.length > 0) {
      return <div className="is-flex is-flex-wrap-wrap">
        { props.user.resources.map(m => {
          return (
            <div className="py-3" style={{width: '48%'}} key={`resource_${m.resourceId}`}>
              <div className="tern-list-item-title">
                { m.link.toLowerCase() === 'engagementetiquette' ? 
                  <a onClick={() => setShowRulesOfEngagementModal(true)}>{m.title}</a>
                :
                  <a href={m.link} rel="noreferrer" target="_blank">{m.title}</a>
                }
              </div>
              {m.description}
            </div>
          )
        })}
      </div>
    }
    else if (!props.user.resources || props.user.resources.length === 0) {
        return <div className="p-3 has-text-centered">No resources yet.  Check back later!</div>
    }
  }

  return (
    <Card>
      <Card.Content>
        <div className="is-flex is-align-items-center">
          <div className="px-4">
            <FontAwesomeIcon className="has-text-info" size="3x" icon={faBookOpen} />
          </div>

          <div>
            <Heading>Resources</Heading>
            <Heading subtitle>
              Information and helpful resources for your journey
            </Heading>
          </div>
        </div>

        <hr />

        <div className="content px-3">
          { RenderResources() }
          {/* <Columns>
            <Columns.Column>
              <ul className="mt-0 tern-list">
                { props.isMentor ? <>
                  { props.groupId === 1 ?
                  <>
                    <li>
                      <div className="tern-list-item-title">
                        <a href="https://youtu.be/OJsth8taBj0" rel="noreferrer" target="_blank">Creating Your Account</a>
                      </div>
                      A video tutorial on creating your account
                    </li>
                    <li>
                    <div className="tern-list-item-title">
                      <a href="https://youtu.be/uKAvw3F1Cog" rel="noreferrer" target="_blank">Preparing For Mentorship</a>
                    </div>
                    Mentoring overview video with Tina Fox
                  </li>
                  </>
                  : <>
                    <li>
                      <div className="tern-list-item-title">
                        <a href="https://youtu.be/XT-qJwH84jU" rel="noreferrer" target="_blank">Am I Qualified?</a>
                      </div>
                      Top 10 tips to become a great Mentor
                    </li>
                  </>
                }
                  </>
                : <><>
                  { props.groupId === 1 && 
                    <li>
                      <div className="tern-list-item-title">
                        <a href="https://youtu.be/OJsth8taBj0" rel="noreferrer" target="_blank">Creating Your Account</a>
                      </div>
                      A video tutorial on creating your account
                    </li>
                  }
                  </>
                  <>
                  { !props.isMentor && props.groupId !== 1 && 
                    <li>
                      <div className="tern-list-item-title">
                        <a href={RecommendedReading} rel="noreferrer" target="_blank">Why TERN?</a>
                      </div>
                    </li>
                  }
                  </></>
                }
                <li>
                  <div className="tern-list-item-title">
                    <a href={FeedbackFrameworkPDF} rel="noreferrer" target="_blank">Feedback Framework</a>
                  </div>
                  PDF document for providing feedback to your { props.isMentor ? 'Mentee' : 'Mentor' }
                </li>
                <li>
                  <div className="tern-list-item-title">
                    <a onClick={() => setShowRulesOfEngagementModal(true)}>Engagement Etiquette</a>
                  </div>
                  Ground rules for interactions
                </li>
              </ul>
            </Columns.Column>
            <Columns.Column>
              <ul className="mt-0 tern-list">
              { props.isMentor && props.groupId !== 1 && 
                    <li>
                      <div className="tern-list-item-title">
                        <a href={RecommendedReading} rel="noreferrer" target="_blank">Recommended Reading </a>
                      </div>
                      <u>The CEO Next Door</u> and other useful materials
                    </li>
                  }
                <li>
                  <div className="tern-list-item-title">
                  <a href={PRIVACY_POLICY_URL} rel="noreferrer" target="_blank">Privacy Policy</a>
                  </div>
                  How we use and protect your information
                </li>
                <li>
                  <div className="tern-list-item-title">
                  <a href={TERMS_AND_CONDITIONS_URL} rel="noreferrer" target="_blank">Terms &amp; Conditions</a>
                  </div>
                  Information on legal aspects of TERN Mentoring
                </li>
                { props.user.groupId === 1 &&
                  <li>
                    <div className="tern-list-item-title">
                      <Link to="/resources/counseling">JMU Counseling Center Info</Link>
                    </div>
                    Resources for mental health and support
                  </li>
                }
              </ul>
            </Columns.Column>
          </Columns> */}
        </div>

      </Card.Content>
      <RulesOfEngagmentModal open={showRulesOfEngagementModal} user={props.user} onClose={() => setShowRulesOfEngagementModal(false)} />
    </Card>

  );
}

export default Resources;