import axios from 'axios';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {

  return new Promise((resolve) => {
    Auth.currentSession().then(session=>{
      let accessToken = session.getAccessToken();
      
      config.headers['Authorization'] = `Bearer ${accessToken.getJwtToken()}`;

      resolve(config);
    })
    .catch(() => {
      console.log('No JTW');
      resolve(config);
    })
  })
});

axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (err) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  
  // Some errors don't get the toast 
  if (err.response?.status === 404 || window.location.href.includes('/login')) {
    return Promise.reject(err);
  }

  // Others shall be toasted
  let text = err.message;
  if (err.response.status === 400) {
    text = 'Bad data'
  }

  text = `${text}.  Contact Tech Support.`;

  toast.error(text, { autoClose: 10000 });

  return Promise.reject(err);
});

function getEndpoint(isMentor, endpoint) {
  return `/api/${isMentor ? 'mentors' : 'mentees'}/${endpoint}`;
}

export function checkValidSignupCode(code) {
  return new Promise(async (resolve, reject) => {
    try {

      let response = await axiosInstance.get(`/api/signupcode/${code}`);
      resolve(response.data);
    }
    catch (err) {
      console.error('checkValidSignupCode err', err);
      reject(err);
    }
  })
}

export function uploadProfilePic(data) {
  return new Promise(async (resolve, reject) => {
    try {

      let response = await axiosInstance.post('/api/profile/pic', data);

      console.log('uploadProfilePic res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('uploadProfilePic err', err);
      reject(err);
    }
  })
}

export function removeProfilePic(data) {
  return new Promise(async (resolve, reject) => {
    try {

      let response = await axiosInstance.delete('/api/profile/pic');

      console.log('removeProfilePic res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('removeProfilePic err', err);
      reject(err);
    }
  })
}

export function setMentorshipFeedback(isMentor, mentorshipId, surveyData, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {

      const data = { 
        ...surveyData
      }

      let response = await axiosInstance.post(`/api/mentorship/${mentorshipId}/${isMentor ? 'mentorfeedback' : 'menteefeedback'}`, data);

      console.log('post res:', response);

      // if (dispatch) {
      //   dispatch( { type: 'SET_SESSION_SURVEY', payload: { sessionId: sessionId, surveyData: { ...surveyData }}});
      // }

      resolve(response.data);
    }
    catch (err) {
      console.error('setSessionSurvey err', err);
      reject(err);
    }
  })
}

export function setSessionSurvey(sessionId, surveyData, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {

      const data = { 
        ...surveyData
      }

      let response = await axiosInstance.post(`/api/sessions/${sessionId}/survey`, data);

      console.log('post res:', response);

      // if (dispatch) {
      //   dispatch( { type: 'SET_SESSION_SURVEY', payload: { sessionId: sessionId, surveyData: { ...surveyData }}});
      // }

      resolve(response.data);
    }
    catch (err) {
      console.error('setSessionSurvey err', err);
      reject(err);
    }
  })
}

export function getSession(sessionId, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {

      let response = await axiosInstance.get(`/api/sessions/${sessionId}`);

      console.log('get res:', response);

      if (dispatch) {
        dispatch( { type: 'SET_SESSION', payload: response.data });
      }

      resolve(response.data);
    }
    catch (err) {
      console.error('setSessionSurvey err', err);
      reject(err);
    }
  })
}

export function scheduleSession(sessionId, sessionDateString, sendInvite, notes, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {

      const data = {
        sessionDate: sessionDateString,
        sendInvite: sendInvite,
        notes: notes,
      }

      let response = await axiosInstance.patch(`/api/sessions/${sessionId}/schedule`, data);

      console.log('post res:', response);

      if (dispatch) {
        dispatch( { type: 'SET_SESSION_DATE', payload: { sessionId: sessionId, ...data }});
      }

      resolve(response.data);
    }
    catch (err) {
      console.error('scheduleSession err', err);
      reject(err);
    }
  })
}

export function addReferrer(data){
  return new Promise(async (resolve, reject) => {
  console.log('data in addReferrer',data);
  try
  {
      let response = await axiosInstance.post('/api/referrals', data);    
      resolve(response.data);
    }
    catch (err) {
      console.error('addReferrer err', err);
      reject(err);
    }
})
}
export function patchUser(isMentor, user, data, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {

      if (!user) {
        reject(Error('Missing user'))
        return;
      }

      let endpoint = '';
      let userTypeId = user?.activeProfile?.contactType?.id;
      if (userTypeId) {
        switch (userTypeId) {
          case 1:
            endpoint = 'mentees';
            break;

          case 2:
            endpoint = 'mentors';
            break;

          default:
            endpoint = '';
        }
      }

      if (endpoint === '') {
        reject(Error('Unknown user type'));
        return;
      }

      let response = await axiosInstance.patch(`/api/${endpoint}/${user.contactId}`, data);

      console.log('patch res:', response);

      if (dispatch) {
        if (!user.offboardingCompletedOn && data.offboardingCompletedOn) {
          dispatch({type:'MENTORSHIP_COMPLETED'});
        }
        
        dispatch( { type: 'SET_USER', payload: response.data });

      }

      resolve(response.data);
    }
    catch (err) {
      console.error('patchUser err', err);
      reject(err);
    }
  })
}

export function createUser(asMentor, cognitoUserId, data, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {
      const endpoint = asMentor ? 'mentors' : 'mentees';
      let response = await axiosInstance.post(`/api/${endpoint}/${cognitoUserId}`, data);

      console.log('post res:', response);

      if (dispatch) {
        dispatch( { type: 'SET_USER', payload: response.data });
      }

      resolve(response.data);
    }
    catch (err) {
      console.error('createUser err', err);
      reject(err);
    }
  })
}

export function getMe() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/me`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMe err', err);
      reject(err);
    }
  })
}

export function getMatchedProfile(contactId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/sharedinfo/${contactId}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMatchedProfile err', err);
      reject(err);
    }
  })
}

export function getUser(id) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/contacts/${id}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getUser err', err);
      reject(err);
    }
  })
}

export function getMatched() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/matched`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMatched err', err);
      reject(err);
    }
  })
}

export function getMatchRuns() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/matchruns`);

      console.log('matchruns res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('matchruns err', err);
      reject(err);
    }
  })
}

export function getMentees() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/mentees`);

      console.log('getMentees res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMentees err', err);
      reject(err);
    }
  })
}

export function getMentors(forMatching) {
  return new Promise(async (resolve, reject) => {
    try {
      let queryParams = {
        forMatching,
      };

      let response = await axiosInstance.get(`/api/mentors?${queryString.stringify(queryParams)}`);

      console.log('getMentors res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMentors err', err);
      reject(err);
    }
  })
}

export function getMatches(isMentor, id) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(getEndpoint(isMentor, `${id}/matched`));

      console.log('getMatches res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMatches err', err);
      reject(err);
    }
  })
}

export function getMatchesByRunDate(runDate) {
  return new Promise(async (resolve, reject) => {
    try {
      let queryParams = {
        matchrundate: runDate.toISOString()
      };

      let response = await axiosInstance.get(`/api/matches?${queryString.stringify(queryParams)}`);

      console.log('getMatchesByRunDate res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMatchesByRunDate err', err);
      reject(err);
    }
  })
}

export function getUsers() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/contacts');

      console.log('post res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getUsers err', err);
      reject(err);
    }
  })
}

export function getAdminDashboardReport() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/admin_report');

      resolve(response.data);
    }
    catch (err) {
      console.error('getAdminDashboardReport err', err);
      reject(err);
    }
  })
}

export function getStaticOptions() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/static_values');

      resolve(response.data);
    }
    catch (err) {
      console.error('getStaticOptions err', err);
      reject(err);
    }
  })
}

export function rollMatch() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/match',
        {
          "matchCriteria":["enneagram","location","language","businessInterest","personalInterest","major","minor","university"]        
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('getStaticOptions err', err);
      reject(err);
    }
  })
}

export function sendFeedback(feedbackType, comment) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/feedback',
        {
          feedbackType:feedbackType,
          comment: comment,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('sendFeedback err', err);
      reject(err);
    }
  })
}

export function getPricing() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/pricing');

      resolve(response.data);
    }
    catch (err) {
      console.error('getPricing err', err);
      reject(err);
    }
  })
}

export function startPaymentIntent() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/startPaymentIntent');

      resolve(response.data);
    }
    catch (err) {
      console.error('startPaymentIntent err', err);
      reject(err);
    }
  })
}

export function startMenteePaymentIntent() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/startMenteePaymentIntent');

      resolve(response.data);
    }
    catch (err) {
      console.error('startMenteePaymentIntent err', err);
      reject(err);
    }
  })
}

export function submitMenteePaymentIntent(indentId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/submitMenteePaymentIntent',
        {
          id: indentId,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('submitMenteePaymentIntent err', err);
      reject(err);
    }
  })
}

export function completeMenteePaymentIntent(indentId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/completeMenteePaymentIntent',
        {
          id: indentId,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('completeMenteePaymentIntent err', err);
      reject(err);
    }
  })
}

export function updatePaymentIntentForUser(indentId, productId, donationAmount) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/updatePaymentIntentForUser',
        {
          id: indentId,
          productId: productId,
          donationAmount: donationAmount,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('updatePaymentIntentForUser err', err);
      reject(err);
    }
  })
}

export function completePaymentIntentForUser(indentId, productId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/completePaymentIntentForUser',
        {
          id: indentId,
          productId: productId,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('completePaymentIntentForUser err', err);
      reject(err);
    }
  })
}

export function updatePaymentIntentForGuest(indentId, donationAmount, name, email) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/updatePaymentIntentForGuest',
        {
          id: indentId,
          donationAmount: donationAmount,
          name: name,
          email: email,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('updatePaymentIntentForGuest err', err);
      reject(err);
    }
  })
}

export function getPendingMatches() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/matches/pending');

      resolve(response.data);
    }
    catch (err) {
      console.error('getPendingMatches err', err);
      reject(err);
    }
  })
}

export function getMentorships() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/mentorships');

      resolve(response.data);
    }
    catch (err) {
      console.error('getMentorships err', err);
      reject(err);
    }
  })
}

export function manualMatch(mentorId, menteeId, menteeFree) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/manualmatch', { mentorId: mentorId, menteeId: menteeId, menteeFree: menteeFree });

      resolve(response.data);
    }
    catch (err) {
      console.error('manualMatch err', err);
      reject(err);
    }
  })
}

export function getUserQuestionsAndAnswers(id, isMentor) {
  const url = isMentor ? '/api/mentors' : '/api/mentees';

  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`${url}/${id}/questions`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getUserQuestionsAndAnswers err', err);
      reject(err);
    }
  })
}

export function setUserQuestionAnswer(mentorOrMenteeId, isMentor, questionId, answer) {
  const url = isMentor ? '/api/mentors' : '/api/mentees';

  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`${url}/${mentorOrMenteeId}/questions/${questionId}`, { answer });

      resolve(response.data);
    }
    catch (err) {
      console.error('setUserQuestionAnswers err', err);
      reject(err);
    }
  })
}

export function setUserQuestionAnswers(mentorOrMenteeId, isMentor, answers) {
  const url = isMentor ? '/api/mentors' : '/api/mentees';

  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`${url}/${mentorOrMenteeId}/answers`, answers);

      resolve(response.data);
    }
    catch (err) {
      console.error('setUserQuestionAnswers err', err);
      reject(err);
    }
  })
}


export function getUserOffboardingQuestionsAndAnswers(id, isMentor) {
  const url = isMentor ? '/api/mentors' : '/api/mentees';

  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`${url}/${id}/offboardingquestions`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getUserOffboardingQuestionsAndAnswers err', err);
      reject(err);
    }
  })
}

export function setUserOffboardingQuestionAnswer(mentorOrMenteeId, isMentor, questionId, answer) {
  const url = isMentor ? '/api/mentors' : '/api/mentees';

  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`${url}/${mentorOrMenteeId}/offboardingquestions/${questionId}`, { answer });

      resolve(response.data);
    }
    catch (err) {
      console.error('setUserQuestionAnswers err', err);
      reject(err);
    }
  })
}

export function setUserOffboardingQuestionAnswers(mentorOrMenteeId, isMentor, answers) {
  const url = isMentor ? '/api/mentors' : '/api/mentees';

  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`${url}/${mentorOrMenteeId}/offboardinganswers`, answers);

      resolve(response.data);
    }
    catch (err) {
      console.error('setUserQuestionAnswers err', err);
      reject(err);
    }
  })
}