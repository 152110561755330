import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Form, Heading } from 'react-bulma-components';
import { useStore } from '../../../store/Store';
import { setMentorshipFeedback, getMatched } from '../../../lib/api';
import SurveyQuestionComponent from '../../SurveyQuestionComponent/SurveyQuestionComponent';

// scottrobinet+tern.mentee2@gmail.com
// scottrobinet+mentor100@gmail.com


const MentorshipFeedbackComponent = forwardRef((props, ref) => {
  const { formValidChange, isMentor } = props;
  const feedback = (isMentor ? props?.mentorship?.mentor_feedback : props?.mentorship?.mentee_feedback) || {};
  const matcheeNoun = isMentor ? 'Mentee' : 'Mentor';
  const alreadyCompleted = (isMentor && props.mentorship.mentor_feedback) || (!isMentor && props.mentorship.mentee_feedback);

  const { state, dispatch } = useStore();

  const [surveyAnswers, setSurveyAnswers] = useState(initialAnswers());
  const [comments, setComments] = useState(feedback?.comments);
  
  function initialAnswers() {
    // Pull the questions from the state
    if (state?.matched?.survey_questions) {
      const questions = JSON.parse(JSON.stringify(state.matched.survey_questions.final_survey));
      // Map the answers, if any
      questions.forEach(question => {
        question.uiType = getQuestionType(question.values);
        if (feedback.survey_answers) {
          const answer = feedback.survey_answers.find(f => f.id === question.id);
          if (answer) {
            question.answer = answer.answer;
          }
        }
      })
      console.log('answers',questions);
      console.log('feedback', feedback);
      return questions;
    }

    return [];
  }

  function getQuestionType(options) {
    if (options.includes('{"yes')) return 'bool';
    if (options.includes('{"1')) return 'rating';
    return 'text';
  }
  
  useImperativeHandle(ref, () => ({
    onDoneClicked() {
      return onDone();
    },
  }));

  useEffect(() => {
    if (formValidChange) {
      formValidChange(surveyAnswers.filter(f => !f.answer).length === 0);
    }
  }, [formValidChange, surveyAnswers])

  // function onCompletionStateChanged(newState) {
  //   if (completionStateChanged) {
  //     completionStateChanged(newState);
  //   }

  //   if (formValidChange) {
  //     formValidChange(false);
  //   }
  // }

  async function onDone() {
    if (alreadyCompleted) return true;

    try {
      const surveyData = {
        surveyAnswers,
        comments,
      }
    
      await setMentorshipFeedback(isMentor, props.mentorship.id, surveyData, dispatch);

      const matchedWithData = await getMatched();
      dispatch( { type: 'SET_MATCHED_WITH', payload: matchedWithData });

      return true;
    }
    catch (err) {
      console.error(err);
      return false;
    }
  }

  useEffect(() => {
    console.log('useffect', surveyAnswers);
  }, [surveyAnswers]);

  function updateAnswer(id, value) {
    console.log('update', id, value);
    setSurveyAnswers((prev) => {
      const newAnswers = [ ...prev ];
      const nut = newAnswers.find(f => f.id === id);
      if (nut) nut.answer = value;

      return newAnswers;
    })
  }

  function surveySection() {

    return <>
      { surveyAnswers.map(answer => 
          <SurveyQuestionComponent key={`survey_question_${answer.id}`} alreadyCompleted={alreadyCompleted} question={answer} updateAnswer={updateAnswer} />
        )
      }

      <Form.Field>
        <Form.Label>Comments to your { matcheeNoun }</Form.Label>
        <Form.Control>
          { alreadyCompleted ? 
            (comments ? 
                <div>{ comments }</div>
              :
                <span className="explanationText has-text-grey-light">No comments provided</span>
            )
            :
              <textarea rows={2} onChange={(e) => setComments(e.target.value)} className="textarea mb-2" placeholder="Enter you comments here..." value={comments} />
          }
        </Form.Control>
      </Form.Field>
    </>
  }

  return (
    <>
      { props.title && 
        <Heading>{props.title}</Heading>
      }

      <p>Please rate and comment on your overall mentoring experience with your {matcheeNoun}</p>
      { surveySection() }

    </>
    );
})


export default MentorshipFeedbackComponent;