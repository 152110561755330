import { useState } from 'react';
import { useStore } from '../../../store/Store';
import { Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { emptySchemaUI } from '../../../schemas';
import OnboardingWizardItem from '../../OnboardingWizardItem/OnboardingWizardItem';
import { patchUser } from '../../../lib/api';

function InfoSheetPersonality(props) {
  const { state, dispatch } = useStore();
  const [ personalityTestText, setPersonalityTestText ] = useState(state.user?.infoSheet?.personalityTestText);

  const formParams = {
    resolver: joiResolver(emptySchemaUI),
  }

  const { handleSubmit } = useForm(formParams);

  async function onSubmit(/*data*/) {
    try {

      const updatedData = {
        personalityTestText: personalityTestText,
      }

      await patchUser(props.isMentor, state.user, updatedData, dispatch);
      
      props.nav.onNext();
    }
    catch (err) {
      console.error('InfoSheetPersonality err', err);
    }
  }

  return (
    <OnboardingWizardItem title="Personality" handleSubmit={handleSubmit(onSubmit)} {...props.nav}>
      <p className="has-text-centered">
      If you have taken a personality profile like Clifton Strengthsfinder, DISC, Enneagram, Myers Briggs please identify which one and your “score/descriptor” for that profile below.</p>
      <div className="wizard-max-inner full-size">
      <Form.Field>
        <Form.Label>Personality Test Details</Form.Label>
        <Form.Input value={personalityTestText} onChange={e => setPersonalityTestText(e.target.value)} ></Form.Input>
      </Form.Field>
    </div>
    </OnboardingWizardItem>
  )
}

export default InfoSheetPersonality;