import { useState } from 'react';
import { useStore } from '../../../store/Store';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { emptySchemaUI } from '../../../schemas';
import MultiInput from '../../MultiInput/MultiInput';
import OnboardingWizardItem from '../../OnboardingWizardItem/OnboardingWizardItem';
import { patchUser } from '../../../lib/api';

function InfoSheetRecommendations(props) {
  const history = useHistory();
  const { state, dispatch } = useStore();
  const [ recommendations, setRecommendations ] = useState(state.user?.infoSheet?.recommendations ? [...state.user.infoSheet.recommendations] : []);
  const redirectLink = new URLSearchParams(useLocation().search).get('r') || '';

  const formParams = {
    resolver: joiResolver(emptySchemaUI),
  }

  const { handleSubmit } = useForm(formParams);

  async function onSubmit(/*data*/) {
    try {

      const updatedData = {
        recommendations: [...recommendations],
      }

      await patchUser(props.isMentor, state.user, updatedData, dispatch);
      
      if (redirectLink) {
        history.push('/' + redirectLink);
      }
      else {
        props.nav.onNext();
      }
    }
    catch (err) {
      console.error('Recommendations err', err);
    }
  }

  return (
    <OnboardingWizardItem title="Recommendations" handleSubmit={handleSubmit(onSubmit)} {...props.nav}>
      <p className="has-text-centered">
        Do you have any recommendations for books, websites, podcasts, authors,<br/>thought leaders or social media feeds you'd like to share?  Tell us!</p>
      <div className="wizard-max-inner full-size">
      <Form.Field>
        <Form.Label>Recommendations</Form.Label>
        <MultiInput values={recommendations} onChange={newRecommendations => setRecommendations([...newRecommendations])} color="info" colorVariant="light">Add another</MultiInput>
      </Form.Field>
    </div>
    </OnboardingWizardItem>
  )
}

export default InfoSheetRecommendations;