import { Block, Box, Heading, Content } from 'react-bulma-components';

function ResourcesCounseling() {

  return (
    <div className="mt-6 container is-max-desktop">
      <Box className="p-6">
        <Heading>TERN FAQ</Heading>
        <Heading subtitle>TERN Frequently Asked Questions</Heading>
      </Box>

      <Box className="px-6 py-5">
        <Block>
          {/* <Heading size="4">Contents</Heading> */}
          <ul>
            <li><a className="is-size-5 has-text-weight-semibold" href="#General-Questions">General Questions</a></li>
            <li><a href="#What-is-TERN-Mentoring">What is TERN Mentoring?</a></li>
            <li><a href="#Where-do-we-meet">Where do we meet?</a></li>
            <li><a href="#Will-I-be-able-to-provide-and-receive-feedback">Will I be able to provide and receive feedback?</a></li>
            <li><a href="#Is-it-possible-to-change-Mentor/Mentee?">Is it possible to change Mentor/Mentee?</a></li>
            <li><a href="#Who-can-be-a-Mentor">Who can be a Mentor?</a></li>
            <li><a href="#Who-can-be-a-Mentee">Who can be a Mentee?</a></li>
            <li><a href="#Can-I-update-my-information-on-the-app">Can I update my information on the app?</a></li>
            <li><a href="#Can-I-get-a-refund">Can I get a refund?</a></li>
          </ul>
        </Block>
        <Block>
          <ul>
            <li><a className="is-size-5 has-text-weight-semibold" href="#Mentor-Questions">Mentor Questions</a></li>
            <li><a href="#Am-I-qualified-to-be-a-Mentor">Am I qualified to be a Mentor?</a></li>
            <li><a href="#How-much-time-is-expected-from-me-as-a-Mentor">How much time is expected from me as a Mentor?</a></li>
            <li><a href="#What-types-of-questions-do-Mentees-ask">What types of questions do Mentees ask (Will there be a guide to support us in being a mentor)?</a></li>
            <li><a href="#How-many-times-may-I-Mentor-in-a-year">How many times may I Mentor in a year?</a></li>
            <li><a href="#May-I-have-more-than-one-Mentee-at-a-time">May I have more than one Mentee at a time?</a></li>
            <li><a href="#What-if-I-do-not-have-time-to-Mentor">What if I do not have time to Mentor?</a></li>
            <li><a href="#What-does-the-Mentor-membership-fee-pay-for">What does the Mentor membership fee pay for?</a></li>
            <li><a href="#May-I-invite-other-individuals-who-are-passionate-about-mentorship-to-join">May I invite other individuals who are passionate about mentorship to join?</a></li>
            <li><a href="#How-else-may-I-support-the-mentee-outside-of-the-mentoring-relationship">How else may I support the mentee outside of the mentoring relationship?</a></li>
          </ul>
        </Block>
        <Block>
          <ul>
            <li><a className="is-size-5 has-text-weight-semibold" href="#Mentee-Questions">Mentee Questions</a></li>
            <li><a href="#What-does-the-Mentee-fee-pay-for">What does the Mentee fee pay for?</a></li>
            <li><a href="#What-happens-after-my-two-year-anniversary-post-graduation">What happens after my two-year anniversary post graduation?</a></li>
            <li><a href="#How-may-I-invite-other-students-to-be-Mentees  ">How may I invite other students to be Mentees?  </a></li>
            <li><a href="#May-I-work-with-the-same-Mentor-again">May I work with the same Mentor again?</a></li>
            <li><a href="#What-if-I-cannot-afford-the-$99 fee to pay for another Mentor match?">What if I cannot afford the $99 fee to pay for another Mentor match?</a></li>
            <li><a href="#Can-a-relative, company, sponsor or friend pay for me to be a Mentee?">Can a relative, company, sponsor or friend pay for me to be a Mentee?</a></li>
          </ul>
        </Block>

        <hr />

        <Heading size={3} id="General-Questions">General Questions</Heading>

        <Content>
          <Heading size={4} id="What-is-TERN-Mentoring">What is TERN Mentoring?</Heading>
          TERN Mentoring is a web-based platform that supports creating mentorship connections for practical learning and intentional networking. Finite time commitment with infinite opportunities focused or free flow, TERN Mentoring is mentorship at its finest.
          Who is supposed to reach out for initial introductions once Mentor and Mentee are matched?
          Once matched, the Mentee and Mentor will be provided with each other’s contact information.  The Mentee is responsible for establishing the initial connection.  Following each meeting, the Mentee and Mentor should determine future meeting dates/times and add them to the UPCOMING EVENTS section of the TERN Mentoring web application.
        </Content>
        <Content>
          <Heading size={4} id="Where-do-we-meet">Where do we meet?</Heading>
          Meetings may take place via phone calls, video calls, or in person if the parties are in proximity to one another.  If meeting in person, TERN recommends that the Mentee choose a location in a familiar public place.
          What should we talk about in our first meeting?
          There is a RESOURCES tab located on the TERN Mentoring web app.  Each meeting has recommended topics of discussion to get the conversation started.  Meetings may also be self-guided as Mentees have specific ideas of what they are seeking to gain out of the mentorship experience.
        </Content>
        <Content>
          <Heading size={4} id="Will-I-be-able-to-provide-and-receive-feedback">Will I be able to provide and receive feedback?</Heading>
          Upon completing each meeting, you will receive an email requesting feedback on your meeting.  This feedback is a short survey (star rating) that takes less than a minute to complete.  After your final mentorship meeting, there is an opportunity to provide more detailed feedback for future reference. Mentees may use the downloadable .pdf as a reference letter for job searches.
          What if the Mentee and Mentor want to continue communication beyond our TERN Mentoring sessions?
          Once matched, it is up to the Mentor/Mentee to determine how best to continue the mentorship relationship started through the TERN Mentoring process.
          Continuing through the TERN Mentoring web application process provides resources to leverage during a Mentee/Mentor relationship, is time-bound, and gives back to the University Community.
        </Content>
        <Content>
          <Heading size={4} id="Is-it-possible-to-change-Mentor/Mentee?">Is it possible to change Mentor/Mentee?</Heading>
          If you feel it is necessary to change Mentor or Mentee, please contact TERN Mentoring, via the chat box on the web application, with your name, the name of the other party, and the reason for the change, and we will reach out to you to support you.
        </Content>
        <Content>
          <Heading size={4} id="Who-can-be-a-Mentor">Who can be a Mentor?</Heading>
          Mentors may include anyone who is an Alumni of the University, Parents of students currently enrolled at the University or who have graduated from the University, Employees of the University and Fans of Mentorship (preferably with close ties to the University).
        </Content>
        <Content>
          <Heading size={4} id="Who-can-be-a-Mentee">Who can be a Mentee?</Heading>
          Mentees are students who are enrolled in the university including up to two years post graduation date.
        </Content>
        <Content>
          <Heading size={4} id="Can-I-update-my-information-on-the-app">Can I update my information on the app?</Heading>
          Yes, you may update your information.  If you are still in the process of signing up and have to leave the application it will remember your last keystroke and you may continue when you are ready. If you have completed your profile and need to make a change, go to “Profile” and you may access whatever you need to update.
        </Content>
        <Content>
          <Heading size={4} id="Can-I-get-a-refund">Can I get a refund?</Heading>
          There are no refunds available at this time.
          <Heading size={3} id="Mentor-Questions">Mentor Questions</Heading>
        </Content>
        <Content>
          <Heading size={4} id="Am-I-qualified-to-be-a-Mentor">Am I qualified to be a Mentor?</Heading>
          If you are interested in sharing your wisdom and experiences for the benefit of the person seeking your comments, then YES, you are qualified to be a Mentor.
        </Content>
        <Content>
          <Heading size={4} id="How-much-time-is-expected-from-me-as-a-Mentor">How much time is expected from me as a Mentor?</Heading>
          The TERN Mentorship program is finite in its approach.  Six sessions of no less than 30 minutes each session to be completed in a 12 week period between Mentee and Mentor.  The mentorship match date serves as the starting point for the term.
        </Content>
        <Content>
          <Heading size={4} id="What-types-of-questions-do-Mentees-ask">What types of questions do Mentees ask (Will there be a guide to support us in being a mentor)?</Heading>
          Mentees ask a myriad of questions.  The best answer is the most honest one.  If there is something you don’t know, simply tell them.  Most questions are of topics Mentors are well aware of and can easily respond with their own experiences.
          TERN has provided a resource guide located on the RESOURCES tab of the app.  This provides suggested topics for each session.  The Mentee/Mentor may also want to self direct the sessions based on interests and curiosities. Free flow or structure, works either way.
        </Content>
        <Content>
          <Heading size={4} id="How-many-times-may-I-Mentor-in-a-year">How many times may I Mentor in a year?</Heading>
          As often as you like.  In Version1, Mentors may only Mentor one Mentee at a time. In future Versions of the app, we will expand to allow Mentors to accept more than one Mentee at a time.
        </Content>
        <Content>
          <Heading size={4} id="May-I-have-more-than-one-Mentee-at-a-time">May I have more than one Mentee at a time?</Heading>
          In the present version of TERN Mentoring, Mentors may only Mentor one Mentee at a time. In future Versions of the app, we will expand to allow Mentors to accept more than one Mentee at a time.
        </Content>
        <Content>
          <Heading size={4} id="What-if-I-do-not-have-time-to-Mentor">What if I do not have time to Mentor?</Heading>
          Once you have a profile established through TERN Mentoring, you will see a toggle ON/OFF button.  Pending your availability, through your membership timeframe,, you may toggle ON or OFF and TERN will know that you are or are not available based on what you have selected. We will send a monthly reminder email to check your availability status.
        </Content>
        <Content>
          <Heading size={4} id="What-does-the-Mentor-membership-fee-pay-for">What does the Mentor membership fee pay for?</Heading>
          Mentor’s membership fee pays for either a one year (Regular) or two year (Journey) Mentor pass in TERN Mentoring where Mentor’s are connected to interested and committed Mentee participants through a finite program with infinite opportunities. All Mentors have access to updated mentorship resources via the TERN Mentoring app. As well as Zoom events. Each membership contributes to a scholarship fund for students at the university as well as supports the JMU Counseling Center. As we continue to build our community, the membership will support member pricing for live events and access to a Mentor’s Forum, connecting Mentors to Mentors.
        </Content>
        <Content>
          <Heading size={4} id="May-I-invite-other-individuals-who-are-passionate-about-mentorship-to-join">May I invite other individuals who are passionate about mentorship to join?</Heading>
          We welcome anyone with a Mentor’s Heart to join us as a Mentor.  Mentors do not have to have graduated from college to become a Mentor.  Life experience and wisdom shared for the benefit of the Mentee is important in being a good Mentor. Feel free to connect others with TERN at WWW.TERNMENTORING.COM.
        </Content>
        <Content>
          <Heading size={4} id="How-else-may-I-support-the-mentee-outside-of-the-mentoring-relationship">How else may I support the mentee outside of the mentoring relationship?</Heading>
          As a Benefit Corporation TERN Mentoring welcomes those who believe in mentorship and would like to donate to our organization so that it may be used for positive societal impact in mentorship and student scholarships.
          <Heading size={3} id="Mentee-Questions">Mentee Questions</Heading>
        </Content>
        <Content>
          <Heading size={4} id="What-does-the-Mentee-fee-pay-for">What does the Mentee fee pay for?</Heading>
          Becoming a TERN Mentoring Mentee provides you with a match to one of our dedicated Mentors who are eager to support your journey, via mentorship.  You will have six sessions at a minimum of 30 minutes per session to be completed in a 12 week period.  Feedback is given from both parties after each session and at the end of the program, the Mentor may opt to provide a longer written recommendation that may be downloaded by the Mentee for use as a job reference.
        </Content>
        <Content>
          <Heading size={4} id="What-happens-after-my-two-year-anniversary-post-graduation">What happens after my two-year anniversary post graduation?</Heading>
          Congratulations on your graduation!  We hope you have enjoyed your experience with TERN Mentoring as a Mentee and should you choose, we would love for you to become part of the TERN Mentoring MENTOR Membership Community. Apply at WWW.TERNMENTORING.COM
        </Content>
        <Content>
          <Heading size={4} id="How-may-I-invite-other-students-to-be-Mentees  ">How may I invite other students to be Mentees?  </Heading>
          Simply share the website information www.ternmentoring.com which will provide them with information and a link to our app to join.
        </Content>
        <Content>
          <Heading size={4} id="May-I-work-with-the-same-Mentor-again">May I work with the same Mentor again?</Heading>
          Mentor and Mentee must agree to work together again and each should send us an email via the chat button.  We will work to make the connection happen.  We also recommend that both parties seek new connections as each connection brings new insights and a new networking opportunity.
        </Content>
        <Content>
          <Heading size={4} id="What-if-I-cannot-afford-the-$99 fee to pay for another Mentor match?">What if I cannot afford the $99 fee to pay for another Mentor match?</Heading>
          TERN Mentoring has a write in option that can be done via the website at www.ternmentoring.com.  Applications are reviewed by the TERN Mentoring Student Advisory Board who then selects the Mentee Scholarship recipients. Once selected, the student is notified via email.
        </Content>
        <Content>
          <Heading size={4} id="Can-a-relative, company, sponsor or friend pay for me to be a Mentee?">Can a relative, company, sponsor or friend pay for me to be a Mentee?</Heading>
          Yes, gifting mentorship to a student is the gift that keeps on giving!  They should either provide you with the funds to sign yourself up or they may donate the $99 fee in your name. Mentees are not charged until a mentorship match is made.  So it is possible to enter your information into the system, await a match and seek the gift from your sponsor at that time.
        </Content>
      </Box>
    </div>
  )
}

export default ResourcesCounseling;