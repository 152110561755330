import { useState, useEffect } from 'react';
import { Box, Heading, Columns } from 'react-bulma-components';
import { arrayToCsvCapitalized, notProvidedString } from '../../lib/stringHelpers.js';
import { getMatchedProfile } from '../../lib/api.js';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture.js';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import ProgressComponent from '../../components/ProgressComponent/ProgressComponent.js';
import { Collection } from '@aws-amplify/ui-react';

function MatchedProfile(props) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ profileData, setProfileData ] = useState([]);

  useEffect(() => {
    if (props?.profile?.contactId) {
      getMatchedProfile(props.profile.contactId)
        .then((data) => {
          setProfileData(data);
        })
        .catch(err => {
          console.error('Could not retrieve profile', err);
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
  }, [props?.profile?.contactId])

  function answerString(answer) {
    if (answer?.answer_value && answer.answer_value.startsWith('[')) {
      try {
        return arrayToCsvCapitalized(JSON.parse(answer.answer_value));
      }
      catch {
        return notProvidedString(answer.answer_value);
      }
    }

    return notProvidedString(answer.answer_value);
  }

  return (<>
      {isLoading ? <ProgressComponent /> :
      <div className="container is-max-widescreen">
        <Box className="is-size-6-mobile is-size-5">
            <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center ">
              <div>
                <Heading align="center">
                  {props.profile.firstName} {props.profile.lastName}
                </Heading>
                <Heading className="mt-3" subtitle align="center">
                  {props.profile.infoSheet?.workCompany && <>{props.profile.infoSheet.workCompany}<br /></>}
                </Heading>

                <div className="mb-3" style={{ height: 350, width: 350, textAlign: 'center', margin: '0 auto'}}>
                  <ProfilePicture picture={props.profile.profilePic} />
                </div>
                <ContactInfo profile={props.profile} />

              </div>

            </div>


          <div className="is-size-6-mobile px-1 py-3">
            { profileData.length > 0 ? 
              profileData.map(answer => {
                return <Columns key={`question_${answer.id}`} className="py-1 is-mobile">
                  <Columns.Column className="is-one-third">
                  <div style={{ fontWeight: 'bold' }}>{answer.name || answer.description}</div>
                  </Columns.Column>
                  <Columns.Column>
                  <div >{answerString(answer)}</div>
                  </Columns.Column>
                </Columns>
              })
            :
              <div className="mt-3 has-text-centered">No additional information available</div>
            }
          </div>
        </Box>
        </div>
      }
    </>)
}

export default MatchedProfile;