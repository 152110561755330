import { Block, Box, Heading, Content } from 'react-bulma-components';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import ThePurpleFolder from '../../../assets/the_purple_folder.pdf';

function ResourcesCounseling() {

  return (
    <div className="mt-3 container is-max-desktop">
      <Box>
        <Heading>JMU Counseling Center Info</Heading>
        <Heading subtitle>Resources for mental health and support</Heading>
      </Box>

      <Box>
        <Content>
        <Heading size={6}>
          Main website and important info:&nbsp;
          <ExternalLink href="https://www.jmu.edu/counselingctr/index.shtml">JMU Counseling Connections</ExternalLink>
        </Heading>

        <Block>
          For mental health emergencies (e.g., suicidal or homicidal thoughts or actions, sexual assault or other significant trauma) occurring Monday-Friday 8am-5pm, call the Counseling Center at 540-568-6552 or come to the 3rd floor of the Student Success Center.
        </Block>

        <Block>
          After regular business hours or on weekends:
          <ul>
            <li>Please call the Counseling Center at (540) 568-6552 and select “Option 1” to connect to the after-hours crisis line</li>
            <li>Students can also access emergency services at<br />
              <address>
                Sentara RMH Medical Hospital<br />
                2010 Health Campus Drive<br />
                Harrisonburg
              </address>
              540-689-1414
            </li>
            <li>Students who reside <b><i>out of town or state</i></b> should contact their local emergency services, call 911, or go to the nearest emergency room.</li>
            <li>Other emergency resources include:</li>
            <ul>
              <li>National Suicide Prevention Lifeline: 1-800-273-TALK (8255) </li>
              <li>Crisis Text Line: Text “HOME” to 741741</li>
              <li>Disaster Stress Hotline: 1-800-985-5990</li>
            </ul>
          </ul>
        </Block>

        <Block>
          <ExternalLink href={ThePurpleFolder}><b>Indicators of Distressed Students and how to guide them to the right support</b></ExternalLink>
        </Block>
        </Content>
      </Box>
    </div>
  );
}

export default ResourcesCounseling;
