const dev = {
  s3Root: "https://tern-assets.s3.amazonaws.com/",
  assetsUrl: "https://tern-assets.s3.amazonaws.com/web/",
  profileImagesUrl: "https://tern-assets.s3.amazonaws.com/profile/",
};

const prod = {
  s3Root: "https://s3.amazonaws.com/assets.ternmentoring.com/",
  assetsUrl: "https://s3.amazonaws.com/assets.ternmentoring.com/web/",
  profileImagesUrl: "https://s3.amazonaws.com/assets.ternmentoring.com/profile/",
};

const stageConfig = process.env.REACT_APP_ENV === "production" ? prod : dev;

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...stageConfig,
}

export default config;
