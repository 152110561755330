import { useState } from 'react';
import { useStore } from '../../../store/Store';
import { emptySchemaUI } from '../../../schemas';
import { patchUser } from '../../../lib/api';
import { Form, Heading } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { alphaSort99 } from '../../../lib/formHelpers';
import OnboardingWizardItem from '../../OnboardingWizardItem/OnboardingWizardItem';

function InfoSheetBusinessInterests(props) {
  const { state, dispatch } = useStore();
  const [ selectedInterests, setSelectedInterests ] = useState(state.user?.infoSheet?.businessInterests ? [...state.user.infoSheet.businessInterests.map((m) => m.id)] : []);

 
  const Interests = [...state.staticValues.businessInterestChoices.sort(alphaSort99)]


  function hasInterest(interest) {
    return selectedInterests.includes(interest);
  }

  function toggleInterest(interest, hasInterest) {
    if (hasInterest) {
      setSelectedInterests([...selectedInterests, interest]);
    }
    else {
      setSelectedInterests(selectedInterests.filter(e => e !== interest));
    }
    return selectedInterests.includes(interest);
  }

  const formParams = {
    resolver: joiResolver(emptySchemaUI),
  }
  const { handleSubmit } = useForm(formParams);

  async function onSubmit(/*data*/) {

    try {
      const updatedData = {
        businessInterests: [...selectedInterests],
      }

      await patchUser(props.isMentor, state.user, updatedData, dispatch);
      
      props.nav.onNext();
    }
    catch (err) {
      console.error('Business Interests err:', err);
    }
  }

  return (
    <OnboardingWizardItem title="Business Interests" description="These will help us find common long-term goals between Mentor and Mentee." handleSubmit={handleSubmit(onSubmit)} {...props.nav}>

      <Heading subtitle>Select all that apply</Heading>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          { Interests.map((interest) => 
            <Form.Field key={`Interest_${interest.id}`} style={{width: '33%'}}>
              <Form.Checkbox checked={hasInterest(interest.id)} onChange={e => toggleInterest(interest.id, e.target.checked)}>{interest.description}</Form.Checkbox>
            </Form.Field>)}
        </div>
    </OnboardingWizardItem>
  )
}

export default InfoSheetBusinessInterests;