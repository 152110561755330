import './Bulma.scss';
import './App.scss';
import './App-mobile.scss';
import 'react-toastify/dist/ReactToastify.css';
import '@aws-amplify/ui-react/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Authenticator } from '@aws-amplify/ui-react';

import './lib/auth';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';

import Store from './store/Store';
import { useStore } from './store/Store';

// import MainNavWithProfile from './components/MainNavWithProfile';
//import MainNavNoDropdown from './components/MainNavNoDropdown';
import Dashboard from './screens/dashboard/Dashboard';
import Profile from './screens/Profile/Profile';
import MentorOnboarding from './screens/MentorOnboarding/MentorOnboarding';
import MenteeOnboarding from './screens/MenteeOnboarding/MenteeOnboarding';
import MenteeInfoSheetWizard from './screens/MenteeInfoSheetWizard/MenteeInfoSheetWizard';
import MentorInfoSheetWizard from './screens/MentorInfoSheetWizard/MentorInfoSheetWizard';
import MentorProfileInfoWizard from './screens/MentorProfileInfoWizard/MentorProfileInfoWizard';
import MenteeProfileInfoWizard from './screens/MenteeProfileInfoWizard/MenteeProfileInfoWizard';
import MenteeMatchProfile from './screens/MenteeMatchProfile/MenteeMatchProfile.js';
import MentorMatchProfile from './screens/MentorMatchProfile/MentorMatchProfile.js';
import EnneagramWizard from './screens/EnneagramWizard/EnneagramWizard';
import Login from './screens/Login/Login';
import Signup from './screens/Signup/Signup';
import Signout from './screens/Signout/Signout';
// import MenteeMatchTypeChoice from './screens/MenteeMatchTypeChoice/MenteeMatchTypeChoice';
// import PaymentScreen from './screens/PaymentScreen/PaymentScreen';
import MakeADonationScreen from './screens/MakeADonationScreen/MakeADonationScreen';
import ResourcesScreen from './screens/Resources/ResourcesScreen/ResourcesScreen';
import FAQScreen from './screens/Resources/FAQScreen/FAQScreen';
import PageNotFound from './screens/PageNotFound/PageNotFound';
import CompanyOnboarding from './screens/CompanyOnboarding/CompanyOnboarding';
import CompanyOffboarding from './screens/CompanyOffboarding/CompanyOffboarding';

function App() {
  console.info(`Hi! This is v${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_ENV}`)
  const [ state, dispatch ] = React.useReducer(Store.reducer);

  return (
    <Authenticator.Provider>
      <Store.StoreProvider value={{state, dispatch}}>
        <ToastContainer autoClose={3000} pauseOnFocusLoss={false} />
        <MainApp />
      </Store.StoreProvider>
    </Authenticator.Provider>
  );
}

function MainApp() {

  const { state } = useStore();
  // const isMentor = state?.user?.activeProfile?.contactType?.id === 2;
  // const isMentorPaid = isMentor && state?.user.subscription?.active;

  return (
    <>
      <Router>
        <Switch>

          <ProtectedRoute exact path="/">
            <Redirect to="/dashboard" /> 
          </ProtectedRoute>

          <Route path="/login">
            <Login />
          </Route>

          <Route path="/signup">
            <Login isSignUp={true} />
          </Route>

          <ProtectedRoute path="/createaccount">
            { !state.user ? <Signup /> : <Redirect to="/" /> } 
          </ProtectedRoute>

          <Route path="/signout">
            <Signout />
          </Route>

          <Route path="/logout">
            <Signout />
          </Route>

          <ProtectedRoute path="/faq">
            <FAQScreen />
          </ProtectedRoute>

          <Route path="/makedonation">
            <MakeADonationScreen />
          </Route>

          <ProtectedRoute path="/resources">
            <ResourcesScreen />
          </ProtectedRoute>

          <ProtectedRoute path="/mentoronboarding">
            <MentorOnboarding />
          </ProtectedRoute>

          <ProtectedRoute path="/menteeonboarding">
            <MenteeOnboarding />
          </ProtectedRoute>

          <ProtectedRoute path="/offboarding">
            <CompanyOffboarding />
          </ProtectedRoute>

          {/* <ProtectedRoute path="/payment">
            <PaymentScreen />
          </ProtectedRoute>

          { isMentor && !isMentorPaid &&
            <Redirect to="/payment" />
          } */}

          <ProtectedRoute path="/dashboard">
            <Dashboard />
          </ProtectedRoute>

          <ProtectedRoute path="/profile">
            <Profile />
          </ProtectedRoute>

          {/* Onboarding for companies other than TERN */}
          <ProtectedRoute path="/onboarding">
            { state?.user?.groupId === 1 ? ( state?.user?.activeProfile?.contactType?.id === 1 ? <MenteeProfileInfoWizard /> : <MentorProfileInfoWizard />)
              : <CompanyOnboarding /> }
          </ProtectedRoute>

          {/* <ProtectedRoute path="/mentorprofileinfo">
            <MentorProfileInfoWizard />
          </ProtectedRoute>

          <ProtectedRoute path="/menteeprofileinfo">
            <MenteeProfileInfoWizard />
          </ProtectedRoute> */}

          {/* <ProtectedRoute path="/matchchoice">
            <MenteeMatchTypeChoice />
          </ProtectedRoute> */}

          <ProtectedRoute path="/matched/profile">
          { state?.user?.activeProfile?.contactType?.id === 1 ? <MentorMatchProfile profile={state.matched?.matched_with} /> : <MenteeMatchProfile profile={state.matched?.matched_with} /> }
          </ProtectedRoute>

          <ProtectedRoute path="/matched/mentor">
            <MentorMatchProfile />
          </ProtectedRoute>

          <ProtectedRoute path="/matched/mentee">
            <MenteeMatchProfile/>
          </ProtectedRoute>

          <ProtectedRoute path="/enneagram">
            <EnneagramWizard />
          </ProtectedRoute>

          <ProtectedRoute path="/infosheet">
            { state?.user?.activeProfile?.contactType?.id === 1 ? <MenteeInfoSheetWizard /> : <MentorInfoSheetWizard/> }
          </ProtectedRoute>

          <ProtectedRoute path="*">
            <PageNotFound />
          </ProtectedRoute>

        </Switch>
    </Router>
    </>
  )
}

export default App;