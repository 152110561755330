import { Modal } from 'react-bulma-components';
import rememberYourEnneagramNumber from '../../assets/remember_your_primary_enneagram_type.png';

function EnneagramModal(props) {

  return (
    <Modal showClose={false} show={props.open} onClose={() => props.onClose()}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p class="modal-card-title">Find Your Enneagram Number</p>
        </Modal.Card.Header>
          <Modal.Card.Body>
            <img src={rememberYourEnneagramNumber} alt="Finding your Enneagram Number" />
          </Modal.Card.Body>
          <Modal.Card.Footer class="modal-card-foot">
            {/* <button class="button is-success" onClick={() => send()}>Send</button>
            <button class="button" onClick={() => props.onClose()}>Cancel</button> */}
          </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default EnneagramModal;