import { Box, Heading, Container, Section } from 'react-bulma-components';
import { arrayToCsvCapitalized, notProvidedString, locationString } from '../../lib/stringHelpers.js';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture.js';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import MatchedProfile from '../../components/MatchedProfile/MatchedProfile';

function MentorMatchProfile(props) {

  const TernProfile = () => {
    return (<>
      <Heading align="center" >
        {props.profile.firstName} {props.profile.lastName}
      </Heading>

      <Heading align="center" subtitle>
        {props.profile.infoSheet?.jobTitle && <>{props.profile.infoSheet?.jobTitle}<br /></>}
        {props.profile.infoSheet?.workCompany || ''}<br />
      </Heading>

      <Box className="mx-6 mb-6 is-size-5">

        <div style={{ float: 'right' }}>

          <Section className="pr-2 py-5" align="center">
            <div className="mb-3" style={{height: 350, width: 350}}>
              <ProfilePicture picture={props.profile.profilePic} />
            </div>
            <ContactInfo profile={props.profile} />
          </Section>

        </div>

        <Section className="pt-5 pb-3 pl-5">
          <strong>Preferred Pronouns:</strong> {notProvidedString(props.profile.preferredPronouns)}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Location:</strong> {notProvidedString(locationString(props.profile.infoSheet?.currentCity, props.profile.infoSheet?.currentState, props.profile.infoSheet?.currentCountry))}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Hometown: </strong> {notProvidedString(locationString(props.profile.infoSheet?.homeTown, props.profile.infoSheet?.hometownState, props.profile.infoSheet?.hometownCountry))}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Alma Mater: </strong> {notProvidedString(props.profile.education.university)}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Graduation Year: </strong> {props.profile.education?.graduationDate && props.profile.education.graduationDate.slice(0, 4)}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Major(s): </strong> {notProvidedString(arrayToCsvCapitalized(props.profile.education?.majorDegrees))}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Minor(s): </strong> {notProvidedString(arrayToCsvCapitalized(props.profile.education?.minorDegrees))}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Advanced Degree(s): </strong> {notProvidedString(arrayToCsvCapitalized(props.profile.education?.advancedDegrees))}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Language(s): </strong> {notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.languages.map(m => m.description)))}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Personality Test(s): </strong> {notProvidedString(props.profile.infoSheet.personalityTestText)}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Interests: </strong> {notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.personalInterests.map(e => e.description), props.profile.infoSheet.otherPersonalInterest))}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Business Interests: </strong>  {notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.businessInterests.map(e => e.description), props.profile.infoSheet.otherBusinessInterest))}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Affinity Groups: </strong> {notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.affinityGroups.map(e => e.description), props.profile.infoSheet.otherAffinityGroup))}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Goals Statement: </strong> {notProvidedString(props.profile.infoSheet?.goalsStatement)}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Advice: </strong> {notProvidedString(props.profile.infoSheet?.reflectiveAdvice)}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Additional Info: </strong> {notProvidedString(props.profile.infoSheet?.additionalInfo)}
        </Section>

        <Section className="pl-5 py-4">
          <strong>Recommendations: </strong> {(props.profile.infoSheet.recommendations && props.profile.infoSheet.recommendations.length > 0) ? props.profile.infoSheet.recommendations.map((m, idx) => <div key={`recommentation_${idx}`}>{m}<br /></div>) : 'None provided'}
        </Section>
      </Box>    
    </>)
  }

  return (

    <Container alignContent="center" className="container is-fullwidth" style={{ paddingTop: '3.25rem' }}>
      { props.profile.groupId === 1 ? <TernProfile /> : <MatchedProfile {...props} /> }
    </Container>
  )

}

export default MentorMatchProfile;