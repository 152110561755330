import { useAuthenticator } from '@aws-amplify/ui-react';
import { Route, Redirect } from "react-router-dom";

import { useRights } from '../../lib/auth';
import MainNavNoDropdown from '../MainNavNoDropdown';

export function ProtectedRoute({ children, location, adminOnly, ...rest }) {
  const { route } = useAuthenticator((context) => [context.user]);
  const { isAdmin } = useRights();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        ['authenticated', 'signOut'].includes(route) ? (
          <MainNavNoDropdown>
            { adminOnly && !isAdmin ? <Redirect to={`/`} />
              : children
            }
          </MainNavNoDropdown>
        ) : (
          <Redirect to={{pathname: "/login", state: { from: location }}} />
        )
      }
    />)
}
