import {
  Route,
  useRouteMatch
} from "react-router-dom";

import ResourcesCounseling from '../ResourcesCounseling/ResourcesCounseling';

function ResourcesScreen() {
  const { path } = useRouteMatch();

  // Eventually this screen should contain a navbar to the left with all resources and relevant routing

  return (
    <div className="mt-6 container is-max-desktop">

        <Route path={`${path}/counseling`}>
          <ResourcesCounseling />
        </Route>
    </div>
  );
}

export default ResourcesScreen;
