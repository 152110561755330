import './Login.scss';

import logo from '../../assets/logo-horizontal.png';
import { I18n, Auth } from 'aws-amplify';
import { useAuthenticator, Authenticator, TextField, View, Button } from '@aws-amplify/ui-react';
import { Redirect, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useStore } from '../../store/Store';
import { Heading } from 'react-bulma-components';
import { getMe, getMatched, getStaticOptions, checkValidSignupCode } from '../../lib/api';
import ProgressComponent from '../../components/ProgressComponent/ProgressComponent';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL} from '../../lib/const';
import { getNonProdName } from '../../lib/auth';

const originalMessage="1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$";
const translatedMessage="Your password must be at least 8 characters and include at least 1 number, uppercase letter and lower case letter.";

I18n.putVocabularies({
  en: {
    [originalMessage]: [translatedMessage]
  }
});

function Login(props) {

  const { route } = useAuthenticator((context) => [context.user]);
  const { dispatch } = useStore();
  const [ redirectUrl, setRedirectUrl ] = useState();
  const [ processing, setProcessing ] = useState(false);
  
  const location = useLocation();
  const initialSignupCode = new URLSearchParams(location.search).get("signupcode");

  const services = {
    async validateCustomSignUp(formData) {
      if (formData['custom:signupcode']) {
        const signupCode = parseInt(formData['custom:signupcode']);
        if (isNaN(signupCode) || signupCode < 100000 || signupCode > 1000000) {
          return {
            'custom:signupcode': 'Code must be 6 digits',
          };
        }
      }
    },

    async handleSignIn(formData) {
      const { username, password } = formData;
      try {
        const cognitoUser = await Auth.signIn({username, password});

        if (cognitoUser?.attributes?.['custom:isadmin'] === '1') {
          Auth.signOut();
          return Promise.reject(new Error('Incorrect username or password.'));
        }

        return Promise.resolve(cognitoUser);
      }
      catch (err) {
        console.log('REJECTED!', err);
        return Promise.reject(err);
      }
    },

    async handleSignUp(formData) {
      let signupCode = formData.attributes['custom:signupcode'];
      if (!signupCode || signupCode.trim().length === 0) {
        signupCode = 254603; // Waiting room
      }

      try {
        await checkValidSignupCode(signupCode);
        formData.attributes['custom:signupcode'] = signupCode.toString();
      }
      catch {
        return Promise.reject(new Error('Signup code is not valid'));
      }

      return Auth.signUp(formData);
    }
  }

  const components = {
    Header() {
      return LogoBanner();
    },

    Footer() {
      return <></>;
      // return LookingToDonateComponent();
    },

    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();

        return (
          <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            <TextField placeholder="Mobile phone number" id="phone_number" name="phone_number" type="text" 
              errorMessage={validationErrors['phone_number']}
              hasError={validationErrors['phone_number']} />

            <TextField placeholder="Sign up code" id="signupcode" name="custom:signupcode" type="text" 
              label="If you have a company or group Sign Up Code enter it here, otherwise leave empty"
              defaultValue={initialSignupCode}
              errorMessage={validationErrors['custom:signupcode']}
              hasError={validationErrors['custom:signupcode']} />

            {/* Append & require Terms & Conditions field to sign up  */}
            <div className="has-text-centered">By signing up you accept our <a href={PRIVACY_POLICY_URL} rel="noreferrer" target="_blank">Privacy Policy</a>
              <br/>and <a href={TERMS_AND_CONDITIONS_URL} rel="noreferrer" target="_blank">Terms &amp; Conditions</a>
            </div>
          </>
        );
      },
    },

    SignIn: {
      Footer() {
        const { toResetPassword } = useAuthenticator();
  
        return (
          <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
          <div className="has-text-centered my-3">By signing in you accept our <a href={PRIVACY_POLICY_URL} rel="noreferrer" target="_blank">Privacy Policy</a>
              <br/>and <a href={TERMS_AND_CONDITIONS_URL} rel="noreferrer" target="_blank">Terms &amp; Conditions</a>
            </div>
        </View>
        
    );
      },
      // FormFields() {
      //   return (
      //     <>
      //       {/* Re-use default `Authenticator.SignUp.FormFields` */}
      //       <Authenticator.SignIn.FormFields />

      //       {/* Append & require Terms & Conditions field to sign up  */}
      //       <div className="has-text-centered">By signing in you accept our <a href="">Privacy Policy</a>
      //         <br/>and <a href="">Terms &amp; Conditions</a>
      //       </div>
      //     </>
      //   );
      // },
    },

    ConfirmResetPassword: {
      Header() {
        return (<>
          <h3 class="amplify-heading amplify-heading--3">Check your email</h3>
          We sent a six digit code to your email.<br/>Enter the code below along with your new password.
        </>)
      }
    },
  }

  const LogoBanner = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex:1000 }}>
        <img className="login-logo" src={logo} alt="Logo" />
        { getNonProdName() && <Heading className="has-text-centered mb-5 is-uppercase" style={{color: 'darkviolet'}}>{getNonProdName()} Server</Heading> }
      </div>
    )
  }

  // const LookingToDonateComponent = () => {
  //   return (
  //     <div className="is-flex is-justify-content-center mt-4">
  //       <div className="donate-box-wrapper">
  //         <Box className="has-text-centered donate-box">
  //           <div className="is-flex is-flex-direction-column is-justify-content-space-between" style={{height: '100%'}}>
  //             <div>
  //               <Heading size="4">Looking to Donate?</Heading>
  //               <Heading subtitle>Make a contribution that will help support<br/>University Scholarships.</Heading>
  //             </div>
  //             <div>
  //               <Link to="/makedonation" className="button is-success donate-button">Make A Donation</Link>
  //             </div>
  //           </div>
  //         </Box>
  //       </div>
  //     </div>
  //   )
  // }

  useEffect(() => {
    let theRedirectUrl = (location.state?.from?.pathname + location.state?.from?.search) || '/';
    if (theRedirectUrl.includes('login') || theRedirectUrl.includes('logout') || theRedirectUrl.includes('signout')) theRedirectUrl = '/';

    async function getTheUser() {
      try {

        let staticValues = await getStaticOptions();
        dispatch( { type: 'SET_STATIC_VALUES', payload: staticValues });

        let userData = await getMe();
        dispatch( { type: 'SET_USER', payload: userData });

        let matchedWithData = await getMatched();
        dispatch( { type: 'SET_MATCHED_WITH', payload: matchedWithData });

        setRedirectUrl(theRedirectUrl);
      }
      catch (err) {
        if (err.response.status === 404) {
          console.error('LOGIN No user');
          setRedirectUrl('/createaccount');
          }
      }
      finally {
        setProcessing(false);
      }
    }

    console.log('login', route);
    if (route === 'authenticated') {
      setProcessing(true);
      getTheUser();
    }
  }, [route])


  return (
    <div>
      <div className="login-background" />
      { processing ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', zIndex: 100 }}>
            <div style={{width: '33%'}}><ProgressComponent /></div>
          </div>
        : <>
          <div style={{ display: 'flex', justifyContent: 'center', zIndex: 100 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex:1000 }}>
              {/* <Authenticator loginMechanisms={['email']} socialProviders={['apple', 'google']}> */}
              <Authenticator initialState={initialSignupCode || props.isSignUp ? 'signUp' : 'signIn'} services={services} components={components} loginMechanisms={['email']}>
                {({ signOut, user }) => (
                  <>{ redirectUrl ? <Redirect to={redirectUrl} /> : null}</>
                )}
              </Authenticator>
            </div>
          </div>

          </>
        }
    </div>
  )
}

export default Login;