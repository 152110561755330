import OnboardingWizardItem from '../OnboardingWizardItem/OnboardingWizardItem';

function CreateProfileGetStarted(props) {

  let text = 'Mentor to help you achieve your goals';

  if (props.isMentor === true) {
    text = 'Mentee to help them achieve their goals';
  }

  return (
    <OnboardingWizardItem title="Welcome!" isGettingStartedPage={true} {...props.nav}>
      <div className="content has-text-centered">
        <p>We need to ask you a few questions to learn more about how we can best match you with a {text}.  The onboarding process only takes a few minutes.</p>
        <p>Don't worry!  You can stop at any time and come back to this - we'll remember where you left off.</p>
      </div>
    </OnboardingWizardItem>
  )

}

export default CreateProfileGetStarted;