import MainNavNoDropdown from '../../components/MainNavNoDropdown';
import Payment from '../../components/Payment/Payment';
import logo from '../../assets/logo-horizontal.png';
import { useStore } from '../../store/Store';

function MakeADonationScreen(props) {
  const { state } = useStore();

  return (
    <>
    <MainNavNoDropdown />
    <div className="is-flex is-justify-content-center">
      <img className="donate-logo" src={logo} alt="Logo" />
    </div>

    <Payment contactInfo={!state.user} showSubscriptionPlans={false} />
    </>
  )
}

export default MakeADonationScreen;