import { useLocation } from 'react-router-dom';
import OnboardingScreen from '../../screens/OnboardingScreen/OnboardingScreen.js';
import CreateProfileEducationInfo from '../../components/CreateProfileEducationInfo/CreateProfileEducationInfo';
import CreateProfilePersonal from '../../components/CreateProfilePersonal/CreateProfilePersonal';
import LanguagePage from '../../components/Languages/Languages.js';
import CreateProfileComplete from '../../components/CreateProfileComplete/CreateProfileComplete';
import AffinityGroups from '../../components/AffinityGroups/AffinityGroups.js';
import CreateProfileSocial from '../../components/CreateProfileSocial/CreateProfileSocial.js';

function MenteeProfileInfoWizard() {
  const redirectLink = new URLSearchParams(useLocation().search).get('r') || '';

  let Pages = [
    [CreateProfileEducationInfo, null],
    [AffinityGroups, null],
    [CreateProfilePersonal, null],
    [CreateProfileSocial,null],
    [LanguagePage, null], // Keep this one last - it has a selective redirect
  ]

  if (!redirectLink) {
    Pages.push([CreateProfileComplete, null])
  }

  return (
    <OnboardingScreen Pages={Pages} isMentor={false} />
  );
}

export default MenteeProfileInfoWizard;